import React, { useState } from 'react';
import {
  CreateIndicatorValueIntegerInput,
  CreateManyIntegerIndicatorValuesInput,
  IndicatorValueForm_LatestIndicatorValue_IntegerIndicatorValueFragment,
  IndicatorValueForm_LatestIndicatorValueFragment,
  IntegerIndicatorValueFormMutationFn,
} from '../../../../../../../../../graphql/generated';
import { NumberInput } from '../../../../../../../../generic/form/NumberEditor';
import { useIndicatorValueForm } from '../../../IndicatorValueFormContext';

export type IntegerIndicatorValueType =
  IndicatorValueForm_LatestIndicatorValueFragment &
    IndicatorValueForm_LatestIndicatorValue_IntegerIndicatorValueFragment;

export type IntegerIndicatorValueFormMutationTypes = {
  input: CreateManyIntegerIndicatorValuesInput;
  item: CreateIndicatorValueIntegerInput;
  mutation: IntegerIndicatorValueFormMutationFn;
}

export const IntegerIndicatorValueForm = (
  {
    indicatorValue,
  }: {
    indicatorValue: CreateIndicatorValueIntegerInput;
  }) => {
  const { addIndicatorValue } = useIndicatorValueForm<IntegerIndicatorValueFormMutationTypes>()
  const [ updatedIndicatorValue, updateIndicatorValue ] = useState<CreateIndicatorValueIntegerInput['integer_value']>(indicatorValue.integer_value)
  const setValue = (integer_value: number)=> {
    updateIndicatorValue(integer_value)
    addIndicatorValue({
      ...indicatorValue,
      integer_value,
    })
  }

  return (
      <div className={'w-[190px]'}>
        <NumberInput  value={updatedIndicatorValue} setValue={setValue} className={'w-[100px]'} />
      </div>
  );
};
