import {
  IndicatorItem_IndicatorFragment,
  StandardItemType,
  SurveyLanguage,
} from 'graphql/generated';
import { ReactNode } from 'react';

export type EditIndicatorButtonProps = {
  indicator: IndicatorItem_IndicatorFragment;
  icon?: ReactNode;
  className?: string;
  initialOpenState?: boolean;
};

export type I18nItem = {
  language: SurveyLanguage;
  name?: string | null;
};
type BaseDimensionItem = {
  i18n?: I18nItem[];
};
export type StandardDimensionItem = {
  standardItemLink: {
    slug: string;
    type: StandardItemType;
  };
} & BaseDimensionItem;
export type CustomDimensionItem = {
  id: string;
} & BaseDimensionItem;

export type DimensionItem = StandardDimensionItem | CustomDimensionItem;

export type EditIndicatorFormValues = {
  i18n: I18nItem[];
  dimension1?: {
    items: DimensionItem[];
  };
  dimension2?: {
    items: DimensionItem[];
  };
};

export const isStandardDimensionItem = (
  item: any,
): item is StandardDimensionItem => {
  return 'standardItemLink' in item;
};

export const isCustomDimensionItem = (
  item: any,
): item is CustomDimensionItem => {
  return 'id' in item;
};
