import React from 'react';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { GapAnalysisSummary } from '../../../../components/project/gapAnalysis/summary/GapAnalysisSummary';
import { TabNavigationLayout } from '../../../../components/generic/nav/TabNavigationLayout';
import { GAP_ANALYSIS_TABS, useGapAnalysisTabs } from '../useGapAnalysisTabs';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { GapAnalysisHero } from '../GapAnalysisHero';

function ProGapAnalysisSummary() {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb />
      <TabNavigationLayout<GAP_ANALYSIS_TABS>
        tabs={useGapAnalysisTabs()}
        activeTab={GAP_ANALYSIS_TABS.SUMMARY}
        renderTabs={(tabsContent) => (
          <GapAnalysisHero>{tabsContent}</GapAnalysisHero>
        )}
      >
        <PageTitle title={t('screen.gap_analysis.summary')} />
        <div className="w-full">
          <GapAnalysisSummary />
        </div>
      </TabNavigationLayout>
    </>
  );
}

export const GapAnalysisSummaryScreen = withProjectContext(
  ProGapAnalysisSummary,
  {
    requiredCompany: true,
  },
);
