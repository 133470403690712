import React, { createContext, FC, ReactNode, useContext } from 'react';

interface ReportDataPointContextType {
  reportDataPoint: string;
}

interface ReportDataPointProviderProps {
  reportDataPoint: string;
  children: ReactNode;
}

const ReportDataPointContext = createContext<ReportDataPointContextType | undefined>(
  undefined,
);

export const ReportDataPointProvider: FC<ReportDataPointProviderProps> = ({
    reportDataPoint,
    children,
  }) => {

  return (
    <ReportDataPointContext.Provider
      value={{
        reportDataPoint,
      }}
    >
      {children}
    </ReportDataPointContext.Provider>
  );
};

export const useReportDataPoint = (): ReportDataPointContextType => {
  const context = useContext(ReportDataPointContext);
  if (!context) {
    throw new Error('useReportDataPoint must be used within a ReportDataPointProvider');
  }
  return context;
};
