import { IroContextualMenu } from '../IroContextualMenu';
import React from 'react';
import {
  IroCellName_IroFragment,
  SurveyLanguage,
} from '../../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { MissingTranslationsWarningIcon } from '../../../../../generic/MissingTranslationsWarningIcon';
import { IroFragment } from '../IroRow';
import { hasHasPotentialNegativeHumanRightsImpactCriteria } from '../../../../../../types/iro.types';
import { JustifyIroButton } from '../../../../audit/justification/type/JustifyIroButton';

export function IroNameCell({
  iro,
}: {
  iro: IroCellName_IroFragment & IroFragment;
}) {
  const { translateProperty, i18n, t } = useTranslation();

  const potentialNegativeHumanRightsImpactCriteria =
    hasHasPotentialNegativeHumanRightsImpactCriteria(iro) &&
    iro.hasPotentialNegativeHumanRightsImpact === true;

  return (
    <div className="flex items-center gap-1 justify-between">
      <div className="grow space-y-1">
        <div className="text-base">
          {translateProperty(iro, 'name')}
          <MissingTranslationsWarningIcon
            entity={iro}
            properties={['name']}
            languages={[i18n.language as SurveyLanguage]}
          />
        </div>
        <div className="flex items-center gap-1">
          {potentialNegativeHumanRightsImpactCriteria && (
            <div className="tag blue small">
              {t('iro.criteria.hasPotentialNegativeHumanRightsImpact')}
            </div>
          )}
          {iro.valueChainItem && (
            <div className="tag blue small">
              {t(`enum:valueChainItem.${iro.valueChainItem}`)}
            </div>
          )}
          {iro.organizationalUnits?.map((ou) => (
            <div key={ou.id} className="tag small">
              {ou.name}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="invisible group-hover:visible">
          <IroContextualMenu
            {...{
              iro,
            }}
          />
        </div>
        <JustifyIroButton iroId={iro.id} />
      </div>
    </div>
  );
}
