import React, { useState } from 'react';
import {
  StandardPicker_StandardFragment,
  useStandardPicker_StandardsQuery,
} from '../../../../graphql/cms/generated';
import { useTranslation } from '@hooks/useTranslation';
import { StringDropdownItem } from '../../../generic/dropdown/StringDropdown.types';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import StringDropdown from '../../../generic/dropdown/StringDropdown';

export function StandardPicker({
  defaultStandardSlug,
  setStandardSlug,
}: {
  defaultStandardSlug: string | null;
  setStandardSlug: (standardSlug: string) => void;
}) {
  const { i18n } = useTranslation();
  const query = useStandardPicker_StandardsQuery({
    variables: {
      locale: i18n.language,
    },
    client: cmsClient,
    fetchPolicy: 'cache-and-network',
  });

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  return (
    <div className="flex items-center gap-4">
      <div className="form-input-label">Norme</div>
      {query.data?.standards && query.data?.standards.length > 0 && (
        <StandardDropdown
          standards={query.data.standards || []}
          defaultStandardSlug={defaultStandardSlug}
          setStandardSlug={setStandardSlug}
        />
      )}
    </div>
  );
}

function StandardDropdown({
  standards,
  defaultStandardSlug,
  setStandardSlug,
}: {
  standards: (StandardPicker_StandardFragment | null)[];
  defaultStandardSlug: string | null;
  setStandardSlug: (standardSlug: string) => void;
}) {
  const initialItem = standards.find(
    (standard) => standard?.slug === defaultStandardSlug,
  );

  const [item, setItem] = useState<StringDropdownItem | null>(
    initialItem
      ? {
          id: initialItem?.slug || null,
          label: initialItem?.name || '',
        }
      : null,
  );

  const pickItem = (item: StringDropdownItem|null) => {
    setItem(item);
    if (item?.id) {
      setStandardSlug(item?.id);
    }
  };

  const availableItems: StringDropdownItem[] =
    standards?.map((standard) => ({
      id: standard?.slug || null,
      label: standard?.name || '',
    })) || [];

  return (
    <StringDropdown
      availableItems={availableItems}
      item={item}
      setItem={pickItem}
    />
  );
}
