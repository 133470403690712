import {
  GapAnalysisDisclosureRequirement_DisclosureRequirementFragment,
  GapAnalysisSummary_DataPointFragment,
  StandardEnum,
  useGapAnalysisDisclosureRequirement_DrByReportQuery,
} from '../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import {
  DatapointTag_DatapointFragment,
  DisclosureRequirementFragment,
  GapAnalysisSummary_DisclosureRequirementFragment,
  GapAnalysisSummary_Topic_TreeFragment,
  useGapAnalysisSummary_TopicsQuery,
} from '../graphql/cms/generated';
import { cmsClient } from '../graphql/clients/cmsClient';
import { extractDatapointsFromDisclosureRequirement } from '../components/project/gapAnalysis/disclosureRequirement/content/utils';

interface DrAggregate {
  cmsTopic: GapAnalysisSummary_Topic_TreeFragment | null | undefined;
  cmsDr: GapAnalysisSummary_DisclosureRequirementFragment | undefined | null;
  reportDr:
    | GapAnalysisDisclosureRequirement_DisclosureRequirementFragment
    | undefined
    | null;
  dataPointAggregates: DataPointAggregate[];
}

interface DataPointAggregate {
  reportDataPoint: GapAnalysisSummary_DataPointFragment | undefined | null;
  cmsDataPoint: DatapointTag_DatapointFragment | undefined | null;
}

/**
 * This hook is used to aggregate the data from the CMS and the report for the disclosure requirements, and related information.
 * such as datapoints, topics, and disclosure requirements
 * @param reportId
 */
export const useReportDrCMSDataAggregator = ({
  reportId,
}: {
  reportId: string;
}): DrAggregate[] => {
  const { i18n } = useTranslation();
  const { data: cmsData, loading: cmsLoading } =
    useGapAnalysisSummary_TopicsQuery({
      client: cmsClient,
      variables: {
        locale: i18n.language,
        standardSlug: StandardEnum.Csrd,
      },
      fetchPolicy: 'cache-and-network',
    });
  const disclosureRequirementReport =
    useGapAnalysisDisclosureRequirement_DrByReportQuery({
      variables: { reportId: reportId || '' },
      fetchPolicy: 'cache-and-network',
    });
  let cmsDRForReportDR: DrAggregate[] = [];
  if (disclosureRequirementReport?.data?.reportDisclosureRequirements) {
    cmsDRForReportDR =
      disclosureRequirementReport.data!.reportDisclosureRequirements.map(
        (reportDr) => {
          const cmsTopic =
            cmsData?.topics?.find(
              (topic) =>
                reportDr?.reportTopic?.standardItemLink.slug === topic?.slug,
            ) || null;
          const cmsDr =
            cmsTopic?.disclosure_requirements?.find(
              (cmsDr) =>
                cmsDr?.slug?.toLowerCase() ===
                reportDr.standardItemLink.slug.toLowerCase(),
            ) || null;
          const dataPointAggregates: DataPointAggregate[] = [];
          // cmsDr should be requested explictly with query returning DisclosureRequirementFragment
          const cmsDp = cmsDr
            ? extractDatapointsFromDisclosureRequirement(
                cmsDr as DisclosureRequirementFragment,
              )
            : [];
          reportDr.dataPoints?.map((dpa) => {
            const cmsDP = cmsDp.find(
              (cmsDp) => cmsDp.slug === dpa.referenceSlug,
            );
            dataPointAggregates.push({
              reportDataPoint: dpa,
              cmsDataPoint: cmsDP,
            });
          });

          return { cmsTopic, cmsDr, dataPointAggregates, reportDr };
        },
      );
  }
  return cmsDRForReportDR;
};
