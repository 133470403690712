import { SurveyLanguage } from '../../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { useFormContext } from 'react-hook-form';
import { EditIndicatorFormValues } from './editIndicator.types';
import React, { useEffect } from 'react';

export const IndicatorI18nNameInput = ({
  language,
}: {
  language: SurveyLanguage;
}) => {
  const { t } = useTranslation();
  const { register, setValue, getValues } =
    useFormContext<EditIndicatorFormValues>();

  useEffect(() => {
    const nameFieldPath =
      language === SurveyLanguage.Fr ? 'i18n.0.name' : 'i18n.1.name';
    const currentValue = getValues(nameFieldPath);
    setValue(nameFieldPath, currentValue);
  }, [language, setValue, getValues]);

  return (
    <input
      type="text"
      className="form-input-text"
      placeholder={t(
        'indicator_collect.disclosure_requirement.indicator.edit.modal.form.name',
        { context: language },
      )}
      {...register(
        language === SurveyLanguage.Fr ? 'i18n.0.name' : 'i18n.1.name',
        { required: language === SurveyLanguage.Fr },
      )}
    />
  );
};
