import messageIllustration from '../../../assets/images/illustrations/diagnostic/message.png';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../AppRoutes';
import { MessageIcon } from '../../../components/icons';
import { IllustratedExplanationMessage } from '../../../components/generic/IllustratedExplanationMessage';
import React from 'react';
import { useTranslation } from '@hooks/useTranslation';

export function IroHeader() {
  const { t } = useTranslation();
  return (
    <div className="p-8">
      <IllustratedExplanationMessage
        title={t('iro.summary.alertConsultation.title')}
        description={t('iro.summary.alertConsultation.content')}
        illustration={<img className="w-20" src={messageIllustration} alt="" />}
      >
        <div className="flex">
          <Link className="button primary" to={AppRoutes.Survey}>
            <MessageIcon className="w-5 h-5" />
            {t('iro.summary.alertConsultation.button.label')}
          </Link>
        </div>
      </IllustratedExplanationMessage>
    </div>
  );
}
