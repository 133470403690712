import React, { useRef } from 'react';
import { DatapointAccordionItem } from './DatapointAccordionItem';
import { useDisclosure } from '../DisclosureContext';
import { DatapointsAccordion_DatapointFragment } from '../../../../../graphql/cms/generated';
import { DatapointsAccordion_ReportDisclosureRequirementFragment } from '../../../../../graphql/generated';

export function DatapointsAccordion({
  datapoints,
  disclosureRequirement,
}: {
  datapoints: DatapointsAccordion_DatapointFragment[];
  disclosureRequirement: DatapointsAccordion_ReportDisclosureRequirementFragment;
}) {
  const { selectedDatapointSlug } = useDisclosure();

  const datapointAccordionItemRefs: Map<
    string,
    React.RefObject<HTMLDivElement>
  > = new Map();
  datapoints.forEach((datapoint) => {
    datapointAccordionItemRefs.set(datapoint.slug as string, useRef(null));
  });

  // Listen to the selectedDatapointSlug and scroll to the selected datapoint
  React.useEffect(() => {
    if (selectedDatapointSlug) {
      const element = datapointAccordionItemRefs.get(
        selectedDatapointSlug,
      )?.current;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [selectedDatapointSlug]);

  return (
    <div className="space-y-2">
      {datapoints.map((datapoint, index) => {
        const reportDatapoint = disclosureRequirement?.dataPoints?.find(
          (reportDatapoint) => reportDatapoint.referenceSlug === datapoint.slug,
        );
        const datapointAccordionItemRef = datapointAccordionItemRefs.get(
          datapoint.slug as string,
        );
        return (
          <div ref={datapointAccordionItemRef}>
            <DatapointAccordionItem
              datapoint={datapoint}
              key={index}
              reportDatapoint={reportDatapoint}
              disclosureRequirement={disclosureRequirement}
            />
          </div>
        );
      })}
    </div>
  );
}
