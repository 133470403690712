export const generateHash = async (
  indicatorId: string,
  dimensionItemsId: string[],
  enterpriseId?: string,
): Promise<string> => {
  const combined = `${indicatorId}|${enterpriseId}|${dimensionItemsId.join(',')}`;

  const encoder = new TextEncoder();
  const data = encoder.encode(combined);

  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
};
