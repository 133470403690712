import {
  DisclosureRequirement_ArFragment,
  DisclosureRequirement_MdrLevel1Fragment,
  DisclosureRequirement_MdrLevel2Fragment,
} from '../../../../../graphql/cms/generated';
import { useTranslation } from '@hooks/useTranslation';
import { DisclosureRequirementMdrLevel2 } from './DisclosureRequirementMdrLevel2';
import React from 'react';
import { filterEmptyDocuments } from '../../../../cms/utils';
import { ApplicationRequirementContent } from './ApplicationRequirementContent';

export const DisclosureRequirementMdrLevel1 = ({
  mdrLevel1,
}: {
  mdrLevel1: DisclosureRequirement_MdrLevel1Fragment;
}) => {
  const { t } = useTranslation();
  const mdrLevel2: DisclosureRequirement_MdrLevel2Fragment[] =
    mdrLevel1.mdr_level_2?.filter(filterEmptyDocuments) || [];
  const ar: DisclosureRequirement_ArFragment[] =
    mdrLevel1.application_requirements?.filter(filterEmptyDocuments) || [];

  return (
    <div className="space-y-4">
      {mdrLevel2.map((mdrLevel2, index) => (
        <DisclosureRequirementMdrLevel2
          mdrLevel2={mdrLevel2}
          key={`mdr_level2_${index}`}
        />
      ))}
      {ar.length > 0 && (
        <h2>
          {t(
            'disclosure_requirements.disclosure_requirement.application_requirements',
          )}
        </h2>
      )}
      {ar.map((applicationRequirement, index) => (
        <ApplicationRequirementContent
          applicationRequirement={applicationRequirement}
          key={`mar_application_requirements_${index}`}
        />
      ))}
    </div>
  );
};
