import React, { useEffect } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { withProjectContext } from '../../../../../providers/withProjectContext';
import { withRouter } from '../../../../withRouter';
import { useNavContext } from '../../../../../providers/NavContextProvider';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../AppRoutes';
import { IndicatorCollectDisclosureRequirement } from '../../../../../components/project/indicatorCollect/disclosureRequirement/IndicatorCollectDisclosureRequirement';

type IndicatorCollectDisclosureRequirementScreenProps = {
  params?: {
    referenceSlug: string;
  };
};

const IndicatorCollectDisclosureRequirementScreenInner =
  withRouter<IndicatorCollectDisclosureRequirementScreenProps>(
    ({ params: { referenceSlug } }) => {
      const { t } = useTranslation();
      const navigate = useNavigate();
      const { setTitle, setActions, setOnClose } = useNavContext();

      useEffect(() => {
        setOnClose(() => () => {
          navigate(AppRoutes.IndicatorCollect);
        });
      }, [setTitle, setActions, setOnClose, navigate, t]);

      return (
        <IndicatorCollectDisclosureRequirement referenceSlug={referenceSlug} />
      );
    },
  );

export const IndicatorCollectDisclosureRequirementScreen = withProjectContext(
  IndicatorCollectDisclosureRequirementScreenInner,
  {
    requiredCompany: true,
  },
);
