import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { GradientScore } from '../../../generic/GradientScore';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import { IroIcon } from '../IroIcon';
import {
  IroTranslation,
  ValueChainItemEnum,
} from '../../../../graphql/generated';
import { ZapThinIcon } from '../../../icons';
import { IroDetailRow } from './iroDetailRow';
import { ColorIcon } from '../../../generic/icon/ColorIcon';
import { ThemeIcon } from '../../../generic/icon/ThemeIcon';
import { Tag } from '../../../badges/Tag';

function getI18nLocale(
  translations: IroTranslation[],
  language: string,
): string {
  if (!translations || translations.length === 0) {
    return '';
  }
  const found = translations.find(
    (itemI18n: { language: string }) => itemI18n.language === language,
  );
  if (found) {
    return found.name || '';
  }
  return translations.find(() => true)?.name || ''; // return first
}

export const useIroListColumns = (): Array<ColumnDef<IroDetailRow>> => {
  const { t, i18n, translateProperty } = useTranslation();
  const columnHelper = createColumnHelper<IroDetailRow>();

  return [
    columnHelper.accessor('iro', {
      cell: (data) => {
        const iro: any = data.getValue();
        return (
          iro && (
            <div
              className={clsx(
                'text-gray-900 font-bold flex gap-3 items-center capitalize',
              )}
            >
              {iro.dtype && <IroIcon iroTypename={iro.dtype} />}
              {getI18nLocale(iro.i18n, i18n.language)}
            </div>
          )
        );
      },
      sortingFn: (a, b) => {
        const itemA = a.getValue('iro') as unknown as {
          i18n: IroTranslation[];
        };
        const itemB = b.getValue('iro') as unknown as {
          i18n: IroTranslation[];
        };
        const nameA = getI18nLocale(itemA.i18n, i18n.language);
        const nameB = getI18nLocale(itemB.i18n, i18n.language);
        return nameA.localeCompare(nameB) || 0;
      },
      sortDescFirst: true,
      header: () => <span>{t('iro.list.table.header.iros.label')}</span>,
      meta: {
        th: {
          className: 'text-left',
        },
        td: {
          className: 'text-left',
        },
      },
    }),
    columnHelper.accessor('stake', {
      cell: (data) => {
        const stake = data.getValue();
        const icon = (
          <ColorIcon
            // @ts-ignore
            color={stake.pillar.color}
            icon={<ThemeIcon themeIcon={stake?.pillar.icon} />}
          />
        );
        const iconWithBorder = (
          <ColorIcon
            // @ts-ignore
            color={stake?.pillar.color}
            icon={<ThemeIcon themeIcon={stake?.pillar.icon} />}
            borderColor={'purple'}
          />
        );
        return (
          <div className="flex items-center justify-between">
            <div
              className={clsx(
                'flex items-center gap-3',
                stake.isDisabled ? 'text-gray-500' : 'text-gray-900 font-bold',
              )}
            >
              {stake.isDisabled ? icon : iconWithBorder}
              <span>{translateProperty(stake, 'name')}</span>
            </div>
          </div>
        );
      },
      enableSorting: false,
      sortDescFirst: false,
      header: () => <span>{t('iro.list.table.header.stake.label')}</span>,
      meta: {
        th: {
          className: 'text-left',
        },
        td: {
          className: 'text-left',
        },
      },
    }),
    columnHelper.accessor('valueChain', {
      cell: (data) => {
        if (!data.getValue().position) {
          return <></>;
        }

        if (data.getValue().position !== ValueChainItemEnum.OwnOperations) {
          return (
            <Tag color="blue">
              {t(`enum:valueChainItem.${data.getValue().position}`)}
            </Tag>
          );
        }

        if (
          !data.getValue().organizationUnits ||
          (data.getValue().organizationUnits?.length || 0) == 0
        ) {
          return (
            <Tag color="blue">
              {t(`enum:valueChainItem.${data.getValue().position}`)}
            </Tag>
          );
        }

        const firstOrganizationUnitName = data
          .getValue()
          .organizationUnits?.at(0)?.name;
        const totalCount = data.getValue().organizationUnits?.length;
        return (
          <div className="flex items-center justify-center">
            <Tag color="blue">{firstOrganizationUnitName}</Tag>
            {totalCount && totalCount > 1 && (
              <Tag color="blue">+{totalCount - 1}</Tag>
            )}
          </div>
        );
      },
      enableSorting: false,
      header: () => t('iro.list.table.header.valueChain.label'),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'text-center w-36',
        },
      },
    }),
    columnHelper.accessor('evaluation', {
      cell: (data) => {
        const evaluation = data.getValue();
        return (
          <div className="w-full flex items-center justify-center">
            {evaluation?.score && evaluation?.isMaterial && (
              <GradientScore
                score={evaluation.score}
                iconRight={<ZapThinIcon />}
              />
            )}
            {evaluation?.score && !evaluation?.isMaterial && (
              <GradientScore score={evaluation.score} />
            )}
          </div>
        );
      },
      sortingFn: (a, b) => {
        return (
          ((a.getValue('evaluation') as unknown as { score: number })?.score ||
            0) -
            (b.getValue('evaluation') as unknown as { score: number })?.score ||
          0
        );
      },
      header: () => t('iro.list.table.header.materialityScore.label'),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'text-center w-36',
        },
      },
    }),
    columnHelper.accessor('actions', {
      cell: (data) => {
        return (
          <div className={'flex items-center gap-3'}>{data.getValue()}</div>
        );
      },
      meta: {
        th: {
          className: 'text-center w-16',
        },
        td: {
          className: 'text-center w-20 pl-0',
        },
      },
      enableSorting: false,
      header: () => '',
    }),
  ] as Array<ColumnDef<IroDetailRow>>;
};
