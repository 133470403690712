import {
  BaseIndicatorValueDto,
  IndicatorValueType,
} from '../graphql/generated';
import { DeepPartial } from '@apollo/client/utilities';

export function isNarrativeType<T extends DeepPartial<BaseIndicatorValueDto>>(
  indicatorValue?: DeepPartial<BaseIndicatorValueDto> | null | undefined,
): indicatorValue is T {
  return (
    (indicatorValue as { __typename?: string })?.__typename ===
    IndicatorValueType.NarrativeIndicatorValue
  );
}

export function isIntegerType<T extends DeepPartial<BaseIndicatorValueDto>>(
  indicatorValue?: DeepPartial<BaseIndicatorValueDto> | null | undefined,
): indicatorValue is T {
  return (
    (indicatorValue as { __typename?: string })?.__typename ===
    IndicatorValueType.IntegerIndicatorValue
  );
}
