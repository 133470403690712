import React from 'react';
import { DisclosureRequirementFragment } from '../../../../../graphql/cms/generated';
import { ChevronDownIcon } from '../../../../icons';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';
import { filterEmptyDocuments } from '../../../../cms/utils';
import { DisclosureRequirementLevel2Content } from './DisclosureRequirementLevel2Content';
import { ApplicationRequirementContent } from './ApplicationRequirementContent';
import { DisclosureRequirementMdr } from './DisclosureRequirementMdr';

export function DisclosureRequirement({
  document,
}: {
  document: DisclosureRequirementFragment;
}) {
  const { t } = useTranslation();
  const level2Dr =
    document.content?.dr_level_2?.filter(filterEmptyDocuments) || [];
  const applicationRequirements =
    document.application_requirements?.filter(filterEmptyDocuments) || [];
  const mdrs =
    document.minimum_disclosure_requirements?.filter(filterEmptyDocuments) ||
    [];

  const [drLevel2Expanded, setDrLevel2Expanded] = React.useState(true);
  const [arExpanded, setArExpanded] = React.useState(true);
  const [mdrExpanded, setMdrExpanded] = React.useState(true);

  return (
    <div className="space-y-8">
      <div className="space-y-4 pl4">
        {level2Dr.length > 0 && (
          <h4 className="flex items-center gap-2">
            <div>
              {t(
                'disclosure_requirements.disclosure_requirement.disclosures_requirements',
              )}
            </div>
            <button
              className="tertiary bg-transparent"
              onClick={() => setDrLevel2Expanded(!drLevel2Expanded)}
            >
              <ChevronDownIcon
                className={clsx(drLevel2Expanded && 'rotate-180')}
              />
            </button>
          </h4>
        )}
        {drLevel2Expanded && (
          <div className="space-y-4">
            {level2Dr.map((drLevel2, index) => (
              <DisclosureRequirementLevel2Content
                drLevel2={drLevel2}
                key={`${document.documentId}_${index}`}
              />
            ))}
          </div>
        )}
      </div>
      <div className="space-y-4">
        {applicationRequirements.length > 0 && (
          <h4 className="flex items-center gap-2">
            <div>
              {t(
                'disclosure_requirements.disclosure_requirement.application_requirements',
              )}
            </div>
            <button
              className="tertiary bg-transparent"
              onClick={() => setArExpanded(!arExpanded)}
            >
              <ChevronDownIcon className={clsx(arExpanded && 'rotate-180')} />
            </button>
          </h4>
        )}
        {arExpanded && (
          <div className="space-y-4">
            {applicationRequirements.map((applicationRequirement, index) => (
              <ApplicationRequirementContent
                applicationRequirement={applicationRequirement}
                key={`${document.documentId}_ar_${index}`}
              />
            ))}
          </div>
        )}
      </div>

      <div className="space-y-4">
        {mdrs.length > 0 && (
          <h4 className="flex items-center gap-2">
            <div>
              {t(
                'disclosure_requirements.disclosure_requirement.minimum_disclosure_requirements',
              )}
            </div>
            <button
              className="tertiary bg-transparent"
              onClick={() => setMdrExpanded(!mdrExpanded)}
            >
              <ChevronDownIcon className={clsx(mdrExpanded && 'rotate-180')} />
            </button>
          </h4>
        )}
        {mdrExpanded && (
          <div className="divide-y divide-gray-100 space-y-4">
            {mdrs.map((mdr, index) => (
              <DisclosureRequirementMdr
                mdr={mdr}
                key={`${document.documentId}_mdr_${index}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
