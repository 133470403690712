import { FetchResult } from '@apollo/client';
import {
  CreateIndicatorValueIntegerInput,
  CreateIndicatorValueNarrativeInput,
  IndicatorItem_IndicatorFragment,
  IndicatorValueForm_LatestIndicatorValuesDocument,
  IndicatorValueType,
  useIntegerIndicatorValueFormMutation,
  useNarrativeIndicatorValueFormMutation,
} from '../../../../../../../../graphql/generated';
import { IntegerIndicatorValueFormMutationTypes } from './integer/IntegerIndicatorValueForm';
import { NarrativeIndicatorValueFormMutationTypes } from './narrative/NarrativeIndicatorValueForm';

type IndicatorTypeToMutationMap = {
  [IndicatorValueType.IntegerIndicatorValue]: IntegerIndicatorValueFormMutationTypes;
  [IndicatorValueType.NarrativeIndicatorValue]: NarrativeIndicatorValueFormMutationTypes;
};

type IndicatorTypeToItem<T extends IndicatorValueType> =
  IndicatorTypeToMutationMap[T]['item'];

type IndicatorTypeToMutation<T extends IndicatorValueType> =
  IndicatorTypeToMutationMap[T]['mutation'];

export const useIndicatorValueTypeMutation = <
  T extends IndicatorValueType,
>(
  indicator: IndicatorItem_IndicatorFragment & { type: T },
  enterpriseId?: string
): {
  mutation: (
    values: IndicatorTypeToItem<T>[],
  ) => Promise<FetchResult<IndicatorTypeToMutation<T>>>;
  loading: boolean;
} => {
  let mutation:
    | ((values: IndicatorTypeToItem<T>[]) => Promise<FetchResult<IndicatorTypeToMutation<T>>>)
    | undefined;
  let loading: boolean | undefined;
  const refetchQueries= [
    {
      query: IndicatorValueForm_LatestIndicatorValuesDocument,
      variables: {
        indicatorId: indicator.id,
        enterpriseId,
      },
    },
  ]
  switch (indicator.type) {
    case IndicatorValueType.IntegerIndicatorValue: {
      const [integerMutation, { loading: integerLoading }] = useIntegerIndicatorValueFormMutation();
      mutation = ((values: CreateIndicatorValueIntegerInput[]) =>
        integerMutation({
          variables: {
            input: {
              integerIndicatorValues: values,
            },
          },
          refetchQueries

        })) as typeof mutation;
      loading = integerLoading;
      break;
    }
    case IndicatorValueType.NarrativeIndicatorValue: {
      const [narrativeMutation, { loading: narrativeLoading }] = useNarrativeIndicatorValueFormMutation();
      mutation = ((values: CreateIndicatorValueNarrativeInput[]) =>
        narrativeMutation({
          variables: {
            input: {
              narrativeIndicatorValues: values,
            },
          },
          refetchQueries
        })) as typeof mutation;
      loading = narrativeLoading;
      break;
    }
    default:
      throw new Error(`The ${indicator.type} type is not managed yet`);
  }

  if (!mutation || loading === undefined) {
    throw new Error(`The ${indicator.type} type is not managed yet`);
  }

  return { mutation, loading };
};
