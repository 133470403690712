import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { HelpTooltip } from '../../generic/HelpTooltip';
import { DatapointTags_Datapoint_StrapiFragment } from '../../../graphql/cms/generated';

export function DatapointTags({
  datapoint,
}: {
  datapoint: DatapointTags_Datapoint_StrapiFragment;
}) {
  const { t } = useTranslation();
  const tags = [];

  if (datapoint.voluntary) {
    tags.push(
      <div className="tag purple font-bold">
        {t(
          'disclosure_requirements.disclosure_requirement.datapoints.voluntary',
        )}
      </div>,
    );
  }

  if (datapoint.alternative) {
    tags.push(
      <div className="tag purple font-bold">
        {t(
          'disclosure_requirements.disclosure_requirement.datapoints.alternative',
        )}
      </div>,
    );
  }

  if (datapoint.conditional) {
    tags.push(
      <div className="tag purple font-bold">
        {t(
          'disclosure_requirements.disclosure_requirement.datapoints.conditional',
        )}
      </div>,
    );
  }

  if (datapoint.phasein_all) {
    tags.push(
      <div className="tag purple font-bold">
        <span>
          {t(
            `disclosure_requirements.disclosure_requirement.datapoints.phase_in.all_label`,
          )}
        </span>
        <HelpTooltip>
          {t(
            `disclosure_requirements.disclosure_requirement.datapoints.phase_in.values.${datapoint.phasein_all}`,
          )}
        </HelpTooltip>
      </div>,
    );
  }

  if (datapoint.phasein_less750) {
    tags.push(
      <div className="tag purple font-bold">
        <span>
          {t(
            `disclosure_requirements.disclosure_requirement.datapoints.phase_in.less_than_750_collaborators_label`,
          )}
        </span>
        <HelpTooltip>
          {t(
            `disclosure_requirements.disclosure_requirement.datapoints.phase_in.values.${datapoint.phasein_less750}`,
          )}
        </HelpTooltip>
      </div>,
    );
  }

  if (tags.length === 0) {
    return null;
  }

  return (
    <div className="flex itemps-center gap-2">
      {tags.map((tag, index) => (
        <div key={`tag ${index}`}>{tag}</div>
      ))}
    </div>
  );
}
