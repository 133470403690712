import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { Table } from '../../../molecules/table/Table';
import {
  BaseIroItemListFragment,
  CompanyFieldsFragment,
  useCompanySubsidiariesQuery,
} from '../../../../graphql/generated';
import { getSortedRowModel } from '@tanstack/react-table';
import clsx from 'clsx';
import { useIroListColumns } from './useIroListColumns';
import { transformDataToIroList } from './transformData';
import { IroDetailRow } from './iroDetailRow';
import { useRightDrawer } from '../../../layout/RightDrawer';
import { FilterIroDrawer } from './FilterIroDrawer';
import { Filters } from './iroFilters.type';
import { SlidersIcon, ZapThinIcon } from '../../../icons';
import { Toggle } from '../../../generic/form/Toggle';
import { NumberCircle } from '../../../generic/NumberCircle';
import emptyView from '../../../../assets/images/illustrations/emptyView.svg';

export const IroList = ({
  iros,
  company,
}: {
  iros: BaseIroItemListFragment[];
  company: CompanyFieldsFragment;
}) => {
  const { t } = useTranslation();
  const dataIroList = transformDataToIroList(iros, company);
  const { data } = useCompanySubsidiariesQuery({
    variables: {
      companyId: company?.id || '',
    },
  });

  const [filters, setFilters] = useState<Filters>({});
  const [materialFilter, setMaterialFilter] = useState<boolean>(false);

  const rightDrawer = useRightDrawer();
  const openRightDrawer = ({
    filters,
    setFilters,
  }: {
    filters: Filters;
    setFilters: (filters: Filters) => void;
  }) => {
    rightDrawer.openDrawerWithComponent(
      <>
        {data?.enterprise && (
          <FilterIroDrawer
            filters={filters}
            setFilters={setFilters}
            company={data?.enterprise}
          />
        )}
      </>,
    );
  };

  function filterIros(): IroDetailRow[] {
    return dataIroList.filter((iro) => {
      if (materialFilter && !iro.evaluation?.isMaterial) {
        return false;
      }
      if (filters.pillarId && iro.stake.pillar.id !== filters.pillarId) {
        return false;
      }
      if (filters.stakeId && iro.stake.id !== filters.stakeId) {
        return false;
      }
      if (
        filters.iroTypes &&
        filters.iroTypes.length > 0 &&
        !filters.iroTypes.includes(iro.iro.dtype)
      ) {
        return false;
      }
      if (
        filters.valueChainPositions &&
        filters.valueChainPositions.length > 0
      ) {
        if (!iro.valueChain.position) return false;
        if (!filters.valueChainPositions.includes(iro.valueChain.position)) {
          return false;
        }
      }
      if (
        filters.organizationUnitIds &&
        filters.organizationUnitIds.length > 0 &&
        !filters.organizationUnitIds.find(
          (orgUnitFilteredId) =>
            iro.valueChain.organizationUnits?.filter(
              (ou) => ou.id === orgUnitFilteredId,
            ).length,
        )
      ) {
        return false;
      }
      return true;
    });
  }

  let dataIroFiltered = filterIros();

  return (
    <div className="w-full mt-5  space-y-8">
      <div className={'flex items-center gap-2'}>
        <h2 className="title-h4">IROs</h2>
        <NumberCircle number={dataIroFiltered.length} size={8} />
        <div
          className={clsx(
            'flex items-center gap-2 font-semibold',
            !materialFilter && 'text-gray-500',
          )}
        >
          <Toggle state={materialFilter} setState={setMaterialFilter} />
          <ZapThinIcon className="h-5 w-5" />
          {t('iro.list.filterByMateriality')}
        </div>

        <div className="grow"></div>
        <button onClick={() => openRightDrawer({ filters, setFilters })}>
          <SlidersIcon className="h-5 w-5" /> {t('global:filters')}
          {countAppliedFilters(filters) > 0 && (
            <NumberCircle number={countAppliedFilters(filters)} size={8} />
          )}
        </button>
      </div>
      <div>
        {dataIroFiltered && (
          <IroTable data={dataIroFiltered} filters={filters} />
        )}
      </div>
    </div>
  );
};

function IroTable({
  data,
  filters,
}: {
  data: IroDetailRow[];
  filters: Filters;
}) {
  const { t } = useTranslation();
  const columns = useIroListColumns();
  const getCellClassName = (row: IroDetailRow, columnId: string) =>
    clsx('text-base');

  return (
    <div>
      <div>
        <Table<IroDetailRow>
          {...{
            tableClassName: 'table table-fixed',
            columns,
            data,
            enableSorting: true,
            enableExpanding: true,
            initialState: {
              sorting: [
                {
                  id: 'iro',
                  desc: true,
                },
              ],
            },
            getSortedRowModel: getSortedRowModel(),
            getCellClassName,
            emptyView: (
              <div className="flex justify-center items-center">
                <img className="" src={emptyView} alt="" />
                <h4>
                  {countAppliedFilters(filters) == 0
                    ? t('iro.list.table.emptyView.default')
                    : t('iro.list.table.emptyView.forFilteredData')}
                </h4>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
}

function countAppliedFilters(filters: Filters) {
  let count = 0;
  count += filters.pillarId ? 1 : 0;
  count += filters.stakeId ? 1 : 0;
  count += (filters.iroTypes?.length || 0) > 0 ? 1 : 0;
  count += (filters.valueChainPositions?.length || 0) > 0 ? 1 : 0;
  count += (filters.organizationUnitIds?.length || 0) > 0 ? 1 : 0;
  return count;
}
