import {
  isNegativeImpactIro,
  isOpportunityIro,
  isPositiveImpactIro,
  isRiskIro,
} from '../../../types/iro.types';
import {
  NegativeImpactIro,
  OpportunityIro,
  PositiveImpactIro,
  RiskIro,
} from '../../../graphql/generated';

export type TidyIros<RT, OT, PIT, NIT> = {
  risks: RT[];
  opportunities: OT[];
  positiveImpacts: PIT[];
  negativeImpacts: NIT[];
};

export function tidyIros<
  RT extends Omit<RiskIro, 'stake' | 'latestJustification'>,
  OT extends Omit<OpportunityIro, 'stake' | 'latestJustification'>,
  PIT extends Omit<PositiveImpactIro, 'stake' | 'latestJustification'>,
  NIT extends Omit<NegativeImpactIro, 'stake' | 'latestJustification'>,
>(iros?: (RT | OT | PIT | NIT | null)[]): TidyIros<RT, OT, PIT, NIT> {
  const tidyIros: TidyIros<RT, OT, PIT, NIT> = {
    risks: [],
    opportunities: [],
    positiveImpacts: [],
    negativeImpacts: [],
  };

  if (!iros) {
    return tidyIros;
  }

  // Filter and classify IROs by type
  iros.forEach((iro) => {
    if (isRiskIro<RT>(iro)) {
      tidyIros.risks.push(iro);
    } else if (isOpportunityIro<OT>(iro)) {
      tidyIros.opportunities.push(iro);
    } else if (isPositiveImpactIro<PIT>(iro)) {
      tidyIros.positiveImpacts.push(iro);
    } else if (isNegativeImpactIro<NIT>(iro)) {
      tidyIros.negativeImpacts.push(iro);
    }
  });

  return tidyIros;
}
