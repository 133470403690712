import {
  StakeStandardTopicsPickerModal_Topic_Level1Fragment,
  StakeStandardTopicsPickerModal_Topic_Level2Fragment,
  StakeStandardTopicsPickerModal_Topic_Level3Fragment,
} from '../../../../graphql/cms/generated';

export type TopicWithChildren =
  | StakeStandardTopicsPickerModal_Topic_Level1Fragment
  | StakeStandardTopicsPickerModal_Topic_Level2Fragment
  | StakeStandardTopicsPickerModal_Topic_Level3Fragment;

export const useIsTopicOrSubtopicSelected = (
  topic: TopicWithChildren,
  selectedTopicIds: string[],
): boolean => {
  if (selectedTopicIds.includes(topic.documentId)) {
    return true;
  }
  if (topic.children) {
    return topic.children.some(
      (child) =>
        child &&
        useIsTopicOrSubtopicSelected(
          child as TopicWithChildren,
          selectedTopicIds,
        ),
    );
  }
  return false;
};
