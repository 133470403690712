import React, { createContext, FC, ReactNode, useContext } from 'react';
import { IroCriteriaValuesDefinitionsContextFragment } from '../../../../graphql/generated';

interface IroCriteriaValuesDefinitionsContextType {
  definitions: IroCriteriaValuesDefinitionsContextFragment[] | undefined | null;
}

interface IroCriteriaValuesDefinitionsProviderProps {
  children: ReactNode;
  definitions: IroCriteriaValuesDefinitionsContextFragment[] | undefined | null;
}

const IroCriteriaValuesDefinitionsContext = createContext<
  IroCriteriaValuesDefinitionsContextType | undefined
>(undefined);

export const IroCriteriaValuesDefinitionsProvider: FC<
  IroCriteriaValuesDefinitionsProviderProps
> = ({ children, definitions }) => {
  return (
    <IroCriteriaValuesDefinitionsContext.Provider value={{ definitions }}>
      {children}
    </IroCriteriaValuesDefinitionsContext.Provider>
  );
};

export const useIroCriteriaValuesDefinitions =
  (): IroCriteriaValuesDefinitionsContextType => {
    const context = useContext(IroCriteriaValuesDefinitionsContext);
    if (!context) {
      throw new Error(
        'useIroCriteriaValuesDefinitions must be used within a IroCriteriaValuesDefinitionsProvider',
      );
    }
    return context;
  };
