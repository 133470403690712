import React from 'react';
import { PageTitle } from 'components/nav/PageTitle';
import {
  AuthRoles,
  FeatureNames,
  useAdminUserScreenQuery,
  useAuthUpdateMutation,
} from 'graphql/generated';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { withAdminNavigation } from '../../withAdminNavigation';
import { AdminNavRoutes } from '../../AdminNav';
import { withRouter } from '../../../withRouter';
import { useTranslation } from '@hooks/useTranslation';
import { ImpersonateButton } from '../../../../components/impersonate/ImpersonateButton';
import { Loader } from '../../../../components/generic/Loader';
import { CompanyLogo } from '../../../../components/project/project/CompanyLogo';
import { Tag } from '../../../../components/badges/Tag';
import { CopyIcon } from 'components/icons';
import { useToast } from 'components/layout/Toast';
import { DataList } from 'components/generic/DataList';

type AdminUserProps = {
  params?: {
    id: string;
  };
};

export const AdminUser = withRouter<AdminUserProps>(({ params: { id } }) => {
  const [updateAuthMutation] = useAuthUpdateMutation();
  const { t } = useTranslation();
  const toast = useToast();
  const userQuery = useAdminUserScreenQuery({
    variables: {
      id,
    },
  });
  const user = userQuery.data?.user;

  if (userQuery.error?.message) {
    return (
      <div className="p-8 flex justify-center items-center w-full">
        <MessageBox type={MessageBoxType.Error}>
          {userQuery.error.message}
        </MessageBox>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  const userRoles = user?.auth?.roles || [];
  const checkRole = (
    role: AuthRoles | FeatureNames,
    wasPreviouslyChecked: boolean,
  ) => {
    if (!user.auth) {
      throw new Error("Can't checkRole for nonexistant Auth");
    }

    const clonedRolesArray = [...userRoles];
    const newRoles = wasPreviouslyChecked
      ? [...clonedRolesArray.filter((previousRole) => previousRole !== role)]
      : [...clonedRolesArray, role];

    updateAuthMutation({
      variables: {
        input: {
          id: user.auth.id,
          roles: newRoles,
        },
      },
    })
      .then((r) => console.log(r))
      .catch((err) => console.error(err));
  }
  if (!user) {
    return <Loader />
  }

  return (
    <>
      <PageTitle
        title={`Admin - ${t('admin:users.form.pageTitle', {
          name: user.firstName.concat(' ', user.lastName),
        })}`}
      />
      <div>
        <div className="my-8 ml-4 space-y-4">
          <div className={"flex items-center gap-5"}>
            {user.company &&
              <CompanyLogo companyId={user.company?.id} size={'big'} /> || <em className={'text-orange-600'}>{t('admin:users.show.noCompany')}</em>
            }
            <h1>{user.firstName} {user.lastName}</h1>
          </div>

          <ImpersonateButton userId={user.id} />
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <DataList
            items={[
              {
                label: t('admin:users.form.field.firstname'),
                value: user.firstName,
              },
              {
                label: t('admin:users.form.field.lastname'),
                value: user.lastName,
              },
              {
                label: t('admin:users.form.field.email'),
                value: <div className="flex items-center gap-2">
                  {user.auth?.email}
                  <CopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(user.auth?.email || '');
                      toast.openToastWithMessage(t('admin:users.form.field.emailCopied'));
                    }}
                    className="ml-2 cursor-pointer"
                  />
                </div>,
              },
              {
                label: t('admin:users.form.field.roles'),
                value: !user.auth ? (
                  <div>[not associated with an Auth]</div>
                ) : (
                  <div className="flex items-center gap-2">
                    {Object.values(AuthRoles).map((role) => {
                      const checked = userRoles.includes(role);
                      return (
                        <label className="tag purple" key={role}>
                          <input
                            type="checkbox"
                            checked={checked}
                            onChange={() => checkRole(role, checked)}
                          />
                          <span>{role}</span>
                        </label>
                      );
                    })}
                  </div>
                )
              },
              {
                label: t('admin:users.form.field.featureFlag'),
                value: !user.auth ? (
                  <div>[not associated with an Auth]</div>
                ) : (
                  <div className="flex items-center gap-2">
                    {Object.values(FeatureNames).map((role) => {
                      const checked = userRoles.includes(role);

                      return (
                        <label className="tag yellow" key={role}>
                          <input
                            type="checkbox"
                            checked={checked}
                            onChange={() => checkRole(role, checked)}
                          />
                          <span>
                          {t(`${'admin:users.form.featureFlag.'.concat(role)}`)}
                        </span>
                        </label>
                      );
                    })}
                  </div>
                ),
              },
              {
                label: t('admin:users.form.field.lastLoggedIn'),
                value: user.auth?.lastSignInDate ? new Date(user.auth?.lastSignInDate).toLocaleString() : t('admin:users.form.field.neverLoggedIn'),
              },
              {
                label: t('admin:users.form.field.projects'),
                value: <div className={'flex flex-col gap-3'}>{user.contributions?.map((contribution) => (
                  <div className={'flex gap-3 items-center font-bold'}>
                    <CompanyLogo companyId={contribution.contributesTo.id} size={'small'} />
                    {contribution.contributesTo.name}
                    {contribution.contributesTo.reports?.map((report)=> {
                      return <Tag>{report.standard}</Tag>
                    })}
                  </div>
                ))}</div>
              }
            ]}
          />
        </div>
      </div>
    </>
  );
})

export const AdminUserScreen = withAdminNavigation(
  AdminUser,
  AdminNavRoutes.Users,
);
