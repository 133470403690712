import {
  PillarStakes_PillarFragment,
  Referential_ReferentialFragment,
  StakeRow_StakeFragment,
  SurveyLanguage,
  UpdatePillarButton_PillarFragment,
  useReferential_ReferentialQuery,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import iso26000Logo from '../../../assets/images/iso26000.png';
import csrdLogo from '../../../assets/images/csrd.png';
import { useModal } from '../../layout/Modal';
import { UpdateStakeModal } from './UpdateStakeModal';
import { LoaderFullscreen } from '../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { PillarIcon } from '../../stake/PillarIcon';
import { UpdatePillarModal } from './UpdatePillarModal';
import { CreatePillarModal } from './CreatePillarModal';
import { ChooseProjectTemplateModal } from '../project/ChooseProjectTemplate/ChooseProjectTemplateModal';
import { EditIcon, LogoIcon, PlusIcon } from '../../icons';
import LanguageDropdown from '../../form/LanguageDropdown';
import clsx from 'clsx';
import { mapThemeColorToTailwindClass } from '../../../services/TailwindService';
import { useTranslation } from '@hooks/useTranslation';
import { AddNewStakeButton } from './AddNewStakeButton';
import { MissingTranslationsWarningIcon } from '../../generic/MissingTranslationsWarningIcon';
import { StakeStandardTopicsPickerModal } from './stakesStandardTopics/StakeStandardTopicsPickerModal';
import { ExportReferentialButton } from './ExportReferentialButton';
import {
  Referential_TopicFragment,
  StandardTopicTag_TopicFragment,
  useReferential_TopicsLazyQuery,
} from '../../../graphql/cms/generated';
import { StandardTopicTag } from './stakesStandardTopics/StandardTopicTag';
import { cmsClient } from '../../../graphql/clients/cmsClient';
import { NotCoveredStandards } from './stakesStandardTopics/NotCoveredStandardTopicsTags';

export function Referential({
  referentialId,
  readonly = false,
}: {
  referentialId: string | null | undefined;
  readonly?: boolean;
}) {
  const [topicsQuery] = useReferential_TopicsLazyQuery({
    client: cmsClient,
    fetchPolicy: 'cache-and-network',
  });
  const [topics, setTopics] = useState<Referential_TopicFragment[]>([]);

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  const reloadTopicsFromCms = (
    referential: Referential_ReferentialFragment,
  ) => {
    const allTopicIds: string[] = referential.pillars.flatMap((pillar) =>
      pillar.stakes.flatMap((stake) => stake.topicIds || []),
    );
    topicsQuery({
      variables: {
        locale: i18n.language,
        topicIds: allTopicIds,
      },
    }).then((result) => {
      if (result.data?.topics) {
        setTopics(result.data.topics.filter(notEmpty) || []);
      }
    });
  };

  const companyReferentialDocumentQuery = useReferential_ReferentialQuery({
    variables: { id: referentialId || '' },
    skip: !referentialId,
    onCompleted: (data) => {
      data.referential && reloadTopicsFromCms(data.referential);
    },
  });

  const refetchAllTopics = () => {
    companyReferentialDocumentQuery.refetch().then((result) => {
      result.data.referential && reloadTopicsFromCms(result.data.referential);
    });
  };

  const referential: Referential_ReferentialFragment | undefined =
    companyReferentialDocumentQuery.data?.referential;
  const { i18n, translateProperty, t } = useTranslation();
  const [language, setLanguage] = useState<SurveyLanguage>(
    i18n.language as SurveyLanguage,
  );

  const selectedTopicIds: string[] =
    referential?.pillars.flatMap(
      (pillar) => pillar.stakes.flatMap((stake) => stake.topicIds || []) || [],
    ) || [];

  if (companyReferentialDocumentQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="space-y-8 main-content">
      {!readonly && referential?.isReferentialLocked && (
        <ReferentialLockedInformationMessage />
      )}
      {referential && <NotCoveredStandards referential={referential} />}
      <div className="space-y-8 divide-y divide-gray-100">
        {!referential && <NoReferentialCard />}
        {referential && (
          <div className="space-y-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <h2 className="title-h3">{t('referential.title')}</h2>
                <ExportReferentialButton
                  referentialId={referential.id}
                  language={language}
                />
              </div>
              <div className="flex items-center gap-2">
                <LanguageDropdown
                  currentLanguage={language}
                  onUpdate={(language) => {
                    setLanguage(language);
                  }}
                />
                {!readonly && (
                  <AddNewPillarButton referentialId={referential.id} />
                )}
              </div>
            </div>
            <div className="space-y-8">
              {referential.pillars
                .toSorted((a, b) =>
                  translateProperty(a, 'name', language).localeCompare(
                    translateProperty(b, 'name', language),
                  ),
                )
                .map((pillar) => (
                  <PillarStakes
                    key={pillar.id}
                    pillar={pillar}
                    language={language}
                    topics={topics}
                    callback={refetchAllTopics}
                    readonly={readonly}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function ReferentialLockedInformationMessage() {
  const { t } = useTranslation();
  return (
    <MessageBox type={MessageBoxType.Warning}>
      <div className="space-y-2">
        <h6>{t('referential.locked_message.title')}</h6>
        <p>{t('referential.locked_message.description')}</p>
      </div>
    </MessageBox>
  );
}

export function NoReferentialCard() {
  const modal = useModal();
  const { t } = useTranslation();
  return (
    <div className="border rounded-xl border-gray-100 flex items-stretch gap-4 pr-4 shadow">
      <div className="bg-gray-50 p-2 rounded-l-xl w-1/3 flex items-center justify-center text-gray-500">
        <div className="w-24 h-24 bg-white rounded-xl p-4 border border-gray-100 shadow-sm flex items-center justify-center -rotate-12 mt-4">
          <LogoIcon />
        </div>
        <div className="w-24 h-24 bg-white rounded-xl p-4 border border-gray-100 shadow-sm flex items-center justify-center rotate-12 mb-4">
          <img src={iso26000Logo} alt="Logo CSRD" />
        </div>
        <div className="w-24 h-24 bg-white rounded-xl p-4 border border-gray-100 shadow-sm flex items-center justify-center -rotate-12 mt-4">
          <img src={csrdLogo} alt="Logo CSRD" />
        </div>
      </div>
      <div className="grow p-8 space-y-4">
        <h2>{t('referential.no_repository.title')}</h2>
        <div className="text-base">
          {t('referential.no_repository.description')}
        </div>
        <button
          onClick={() =>
            modal.openModalWithComponent(
              <ChooseProjectTemplateModal />,
              '',
              true,
              false,
              'w-4/5',
            )
          }
          className="primary purple"
        >
          <span>{t('referential.no_repository.cta')}</span>
        </button>
      </div>
    </div>
  );
}

function PillarStakes({
  pillar,
  language,
  topics,
  callback,
  readonly = false,
}: {
  pillar: PillarStakes_PillarFragment;
  language: SurveyLanguage;
  topics: StandardTopicTag_TopicFragment[];
  callback: () => void;
  readonly?: boolean;
}) {
  const { translateProperty, t } = useTranslation();
  const sortedStakes = pillar.stakes.toSorted((a, b) => {
    return translateProperty(a, 'name', language).localeCompare(
      translateProperty(b, 'name', language),
    );
  });
  return (
    <div className="space-y-4">
      <h2 className="flex items-center gap-2 title-h5">
        <div
          className={clsx(
            'p-2 rounded-lg',
            mapThemeColorToTailwindClass(pillar.color, 100, 'bg'),
            mapThemeColorToTailwindClass(pillar.color, 900, 'text'),
          )}
        >
          <PillarIcon pillar={pillar} />
        </div>
        {translateProperty(pillar, 'name', language)}
        <MissingTranslationsWarningIcon
          entity={pillar}
          properties={['name']}
          languages={[language]}
        />
        {!readonly && <UpdatePillarButton pillar={pillar} />}
      </h2>
      <table>
        <thead>
          <tr>
            <th className="text-left">
              <div className="w-full flex justify-between items-center">
                <div>{t('referential.table.header.stake')}</div>
                {!readonly && <AddNewStakeButton pillar={pillar} />}
              </div>
            </th>
            <th className="w-1/3 min-w-52">
              {t('referential.table.header.linked_standard_topics')}
            </th>
            {!readonly && <th className="w-20"></th>}
          </tr>
        </thead>
        <tbody>
          {sortedStakes.length === 0 && (
            <tr>
              <td colSpan={3} className="text-center">
                {t('referential.table.no_stake')}
              </td>
            </tr>
          )}
          {sortedStakes.map((stake) => (
            <StakeRow
              key={stake.id}
              stake={stake}
              language={language}
              topics={topics}
              callback={callback}
              readonly={readonly}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function AddNewPillarButton({ referentialId }: { referentialId: string }) {
  const { t } = useTranslation();
  const modal = useModal();
  const openCreatePillarModal = () => {
    modal.openModalWithComponent(
      <CreatePillarModal referentialId={referentialId} />,
      t('referential.form.pillar.add_pillar'),
      false,
      false,
      null,
      'p-0',
    );
  };

  return (
    <button className="secondary" onClick={openCreatePillarModal}>
      <PlusIcon />
      <div>{t('referential.form.pillar.add_pillar')}</div>
    </button>
  );
}

function StakeRow({
  stake,
  language,
  topics,
  callback,
  readonly = false,
}: {
  stake: StakeRow_StakeFragment;
  language: SurveyLanguage;
  topics: StandardTopicTag_TopicFragment[];
  callback: () => void;
  readonly?: boolean;
}) {
  const { translateProperty, t } = useTranslation();
  const modal = useModal();
  const openUpdateStakeModal = () => {
    modal.openModalWithComponent(
      <UpdateStakeModal stake={stake} />,
      t('referential.form.stake.update_stake'),
      true,
      false,
    );
  };

  const openStakeStandardTopicPickerModal = () => {
    modal.openModalWithComponent(
      <StakeStandardTopicsPickerModal stake={stake} callback={callback} />,
      t('referential.form.stake.link_standard_topics'),
      false,
      true,
      'w-5/12',
      'p-6',
    );
  };

  return (
    <tr>
      <td className="border-r">
        <div className="font-semibold text-base flex items-center gap-2">
          {translateProperty(stake, 'name', language)}
          <MissingTranslationsWarningIcon
            entity={stake}
            properties={['name']}
            languages={[language]}
          />
        </div>
      </td>
      <td className="border-r">
        <div className="flex items-center gap-0.5 flex-wrap">
          {stake.topicIds &&
            stake.topicIds.length > 0 &&
            stake.topicIds?.map((topicId) => {
              const topic = topics.find((t) => t.documentId === topicId);
              return (
                topic && (
                  <StandardTopicTag key={topic.documentId} topic={topic} />
                )
              );
            })}
          {!readonly && (
            <>
              <button
                className="tertiary"
                onClick={openStakeStandardTopicPickerModal}
              >
                <PlusIcon />
              </button>
            </>
          )}
        </div>
      </td>
      {!readonly && (
        <td>
          {!readonly && (
            <button onClick={openUpdateStakeModal} className="tertiary">
              <EditIcon className="shrink-0 w-4 h-4 text-gray-300" />
            </button>
          )}
        </td>
      )}
    </tr>
  );
}

function UpdatePillarButton({
  pillar,
}: {
  pillar: UpdatePillarButton_PillarFragment;
}) {
  const { t } = useTranslation();
  const modal = useModal();
  const openUpdatePillarModal = () => {
    modal.openModalWithComponent(
      <UpdatePillarModal pillar={pillar} />,
      t('referential.form.pillar.update_pillar'),
      false,
      false,
      null,
      'p-0',
    );
  };
  return (
    <button
      className="unstyled small text-gray-500"
      onClick={openUpdatePillarModal}
    >
      <EditIcon />
    </button>
  );
}
