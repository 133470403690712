import React, { useState } from 'react';
import {
  IroEvaluation_IroDocument,
  IroSummaryScreenDocument,
  IroType,
  SurveyLanguage,
  useCompanySubsidiariesQuery,
  useCreateIroModalMutation,
  ValueChainItemEnum,
} from '../../../../../../graphql/generated';
import { useToast } from '../../../../../layout/Toast';
import { useModal } from '../../../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import LanguageDropdown from '../../../../../form/LanguageDropdown';
import { Loader } from '../../../../../generic/Loader';
import { XIcon } from '../../../../../icons';
import { StringDropdownItem } from '../../../../../generic/dropdown/StringDropdown.types';
import StringDropdown from '../../../../../generic/dropdown/StringDropdown';
import { OrganizationalUnitDropdown } from '../../../../project/company/OrganizationalUnitDropdown';
import { useProjectContext } from '../../../../../../providers/ProjectContextProvider';
import { Toggle } from '../../../../../generic/form/Toggle';

type CreateIroModalProps = {
  stakeId: string;
  referentialId: string;
  type: IroType;
};

export function CreateIroModal({
  type,
  stakeId,
  referentialId,
}: CreateIroModalProps) {
  const toast = useToast();
  const modal = useModal();
  const { t } = useTranslation();
  const [language, setLanguage] = useState<SurveyLanguage>(SurveyLanguage.Fr);
  const [i18nValues, setI18nValues] = useState([
    { language: SurveyLanguage.Fr, name: '' },
    { language: SurveyLanguage.En, name: '' },
  ]);
  const translation = i18nValues?.find((i18n) => i18n.language === language);

  const [isLoading, setIsLoading] = useState(false);

  const company = useProjectContext();
  const enterprise = useCompanySubsidiariesQuery({
    variables: {
      companyId: company?.enterprise?.id || '',
    },
  });

  const pickLanguage = (newLanguage: SurveyLanguage) => {
    setLanguage(newLanguage);
    // Inject language if not present
    if (!i18nValues.find((i18n) => i18n.language === newLanguage)) {
      const newI18nValues = [...i18nValues];
      newI18nValues.push({
        language: newLanguage,
        name: '',
      });
      setI18nValues(newI18nValues);
    }
  };

  const [selectedOrgUnitId] = useState<string[] | null>([]);

  let selectedOrgUnit: { id: string }[] = [];

  function selectOrgUnit(selectedIds: string[] | null) {
    selectedOrgUnit =
      selectedIds?.map((id) => {
        return { id };
      }) || [];
  }

  const valueChainItems: StringDropdownItem[] = Object.values(
    ValueChainItemEnum,
  ).map((value) => ({
    id: value,
    label: t(`enum:valueChainItem.${value}`),
  }));
  const [valueChainItem, setValueChainItem] =
    useState<StringDropdownItem | null>(valueChainItems[1]);

  const [
    hasPotentialNegativeHumanRightsImpact,
    setHasPotentialNegativeHumanRightsImpact,
  ] = useState(false);

  const [createIro] = useCreateIroModalMutation();

  const handleSubmit = () => {
    setIsLoading(true);
    createIro({
      variables: {
        input: {
          valueChainItem: valueChainItem?.id as ValueChainItemEnum,
          type,
          stake: {
            id: stakeId,
          },
          i18n: i18nValues,
          organizationalUnits: selectedOrgUnit,
          hasPotentialNegativeHumanRightsImpact,
        },
      },
      refetchQueries: () => [
        {
          query: IroEvaluation_IroDocument,
          variables: {
            stakeId,
          },
        },
        {
          query: IroSummaryScreenDocument,
          variables: {
            id: referentialId,
          },
        },
      ],
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:iro.form.create.success'));
        modal.closeModal();
      })
      .catch(() => {
        toast.openToastWithError(t('toast:iro.form.create.error'));
      })
      .finally(() => setIsLoading(false));
  };

  const editField = (field: string, value: string) => {
    const newI18nValues = i18nValues.map((i18n) => {
      if (i18n.language === language) {
        return {
          ...i18n,
          [field]: value,
        };
      }
      return i18n;
    });
    setI18nValues(newI18nValues);
  };

  return (
    <div className="flex flex-col max-w-2xl h-full">
      <div className="bg-gray-50 flex items-center gap-4 p-6 w-full justify-between border-b border-gray-100">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-100"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <h4 className="grow">{t(`enum:iroType.${type}`)}</h4>
        <LanguageDropdown onUpdate={(language) => pickLanguage(language)} />
      </div>
      <div className="p-6 space-y-6 divide-y divide-gray-100 grow overflow-y-scroll">
        <div className="space-y-6">
          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="form-input-label">
              {t('iro.form.name')}
            </label>
            <input
              type="text"
              id="title"
              className="form-input-text"
              placeholder={t('iro.form.name', {
                context: language,
              })}
              value={translation?.name || ''}
              onChange={(e) => editField('name', e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="p-6 space-y-6 divide-y divide-gray-100 grow overflow-y-scroll">
        <div className="space-y-6">
          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="form-input-label">
              {t('translation:iro.form.value_chain_position')}
            </label>
            <StringDropdown
              availableItems={valueChainItems}
              item={valueChainItem}
              setItem={(item) => setValueChainItem(item)}
            />
          </div>
        </div>
      </div>

      {valueChainItem != null &&
        enterprise.data?.enterprise &&
        valueChainItem.id === ValueChainItemEnum.OwnOperations && (
          <div className="p-6 space-y-6 divide-y divide-gray-100 grow overflow-y-scroll">
            <div className="space-y-6">
              <div className="flex flex-col gap-2">
                <label htmlFor="title" className="form-input-label">
                  {t('translation:iro.form.organization_unit')}
                </label>
                <OrganizationalUnitDropdown
                  multiple={true}
                  company={enterprise.data?.enterprise}
                  selectedOrgUnitId={selectedOrgUnitId}
                  setMultipleSelectedOrgUnitId={selectOrgUnit}
                />
              </div>
            </div>
          </div>
        )}

      {type === IroType.NegativeImpact && (
        <div className="p-6 space-y-6 divide-y divide-gray-100 grow overflow-y-scroll">
          <div className="flex items-start gap-2">
            <Toggle
              state={hasPotentialNegativeHumanRightsImpact}
              setState={setHasPotentialNegativeHumanRightsImpact}
            />
            <label
              htmlFor="title"
              className="form-input-label cursor-pointer"
              onClick={() =>
                setHasPotentialNegativeHumanRightsImpact(
                  !hasPotentialNegativeHumanRightsImpact,
                )
              }
            >
              {t('translation:iro.form.hasPotentialNegativeHumanRightsImpact')}
            </label>
          </div>
        </div>
      )}

      <div className="flex gap-2 justify-between px-6 py-4 border-t border-gray-100">
        <button
          className="primary"
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          {isLoading ? <Loader /> : t('global:save')}
        </button>
      </div>
    </div>
  );
}
