import React, { useEffect, useMemo } from 'react';
import { useModal } from '../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import {
  FeatureNames,
  GapAnalysisSummary_TopicFragment,
  StandardEnum,
  useGapAnalysisSummary_StakesFromTopicIdsLazyQuery,
  useGapAnalysisSummaryQuery,
} from '../../../../graphql/generated';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { Loader } from '../../../generic/Loader';
import {
  GapAnalysisTopicDRTableRow,
  prepareData,
  useGapAnalysisTopicDRTableColumns,
} from './useGapAnalysisTopicDRTableColumns';
import { Table } from 'components/molecules/table/Table';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import clsx from 'clsx';
import {
  GapAnalysisSummary_Topic_TreeFragment,
  useGapAnalysisSummary_TopicsQuery,
} from '../../../../graphql/cms/generated';
import { ChooseReportDisclosureRequirementsModal } from './ChooseReportTopicModal/ChooseReportDisclosureRequirementsModal';
import { SkeletonLoader } from '../../../generic/loader/SkeletonLoader';
import { useNavigate } from 'react-router-dom';
import { NoData } from '../../../generic/NoData';
import { getSortedRowModel } from '@tanstack/react-table';
import { NeedToCreateReportFirst } from '../../report/NeedToCreateReportFirst';
import { useFeatureEnabled } from '@hooks/useFeatureEnabled';

export function GapAnalysisSummary() {
  const { t, i18n } = useTranslation();
  const context = useProjectContext();
  const modal = useModal();

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useGapAnalysisSummaryQuery({
    variables: {
      input: {
        company: { id: context?.enterprise?.id! },
        standard: StandardEnum.Csrd,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const isIndicatorCollectFeatureEnabled = useFeatureEnabled(
    FeatureNames.CsrdReportCollect,
  );

  const { data: cmsData, loading: cmsLoading } =
    useGapAnalysisSummary_TopicsQuery({
      client: cmsClient,
      variables: {
        locale: i18n.language,
        standardSlug: StandardEnum.Csrd,
      },
      fetchPolicy: 'cache-and-network',
    });

  const [loadStakes, { data: stakeData, loading: stakesLoading }] =
    useGapAnalysisSummary_StakesFromTopicIdsLazyQuery();

  const topicsTreeDocumentIds = useMemo(() => {
    const topics = cmsData?.topics || [];
    const topicsAndChildrenAndChildrenOfChildrenDocumentIds: string[] = [];
    topics.forEach((topic) => {
      topicsAndChildrenAndChildrenOfChildrenDocumentIds.push(
        topic?.documentId!,
      );
      topic?.children?.forEach((child) => {
        topicsAndChildrenAndChildrenOfChildrenDocumentIds.push(
          child?.documentId!,
        );
        child?.children?.forEach((childOfChild) => {
          topicsAndChildrenAndChildrenOfChildrenDocumentIds.push(
            childOfChild?.documentId!,
          );
        });
      });
    });
    return topicsAndChildrenAndChildrenOfChildrenDocumentIds;
  }, [cmsData]);

  useEffect(() => {
    if (cmsData?.topics && appData?.companyReport?.topics) {
      loadStakes({
        variables: {
          input: {
            companyId: context?.enterprise?.id!,
            topicIds: topicsTreeDocumentIds,
          },
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [cmsData, appData, loadStakes, context]);

  const openChooseReportDisclosureRequirementModal = () => {
    if (appData) {
      const filteredTopics = cmsData?.topics.filter(
        (topic): topic is NonNullable<typeof topic> => topic !== null,
      );
      modal.openModalWithComponent(
        <ChooseReportDisclosureRequirementsModal
          report={appData.companyReport}
          cmsTopics={filteredTopics ?? []}
          stakes={stakeData?.diagnosticStakesFromTopicIds ?? []}
        />,
        t('gap_analysis.summary.modal_title'),
        false,
        false,
        undefined,
        'p-0',
      );
    }
  };
  const navigate = useNavigate();
  const columns = useGapAnalysisTopicDRTableColumns();
  const getCellClassName = (
    row: GapAnalysisTopicDRTableRow,
    columnId: string,
  ) =>
    clsx('text-base', {
      'border-r-0':
        row.isDisabled &&
        ['datapointAvailability', 'totalNumberOfDatapoints'].includes(columnId),
    });
  const isLoading = appLoading || cmsLoading || stakesLoading;
  const chooseDisclosureRequirementsButton = (
    <button
      className={clsx('primary purple', { 'purple-500': isLoading })}
      disabled={isLoading}
      onClick={openChooseReportDisclosureRequirementModal}
    >
      {isLoading && <Loader />}
      {t('gap_analysis.summary.modal_cta')}
    </button>
  );
  if (appError) {
    return <NeedToCreateReportFirst />;
  }
  const appTopics = appData?.companyReport?.topics || [];
  return (
    <div className="main-content space-y-8 px-16 py-8">
      <div className="flex justify-between mb-10">
        <h1>{t('gap_analysis.summary.modal_title')}</h1>
        {appTopics.length > 0 && chooseDisclosureRequirementsButton}
      </div>
      {appLoading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : appTopics.length === 0 ? (
        <NoData
          text={t('gap_analysis.summary.noData', {
            companyName: context?.enterprise?.name ?? '',
          })}
          actions={[
            {
              key: 'chooseDisclosureRequirements',
              component: chooseDisclosureRequirementsButton,
            },
          ]}
        />
      ) : (
        appTopics
          .map(
            (
              topic,
            ): {
              topic: GapAnalysisSummary_TopicFragment;
              cmsTopic?: GapAnalysisSummary_Topic_TreeFragment;
              data: GapAnalysisTopicDRTableRow[];
            } => {
              return aggregateTopicWithCmsTopicAndData({
                topic,
                cmsData,
                navigate,
                t,
                isIndicatorCollectFeatureEnabled,
              });
            },
          )
          .sort(
            (a, b) => (a.cmsTopic?.position || 0) - (b.cmsTopic?.position || 0),
          )
          .map((item) => {
            return (
              <div
                className="flex flex-col gap-4"
                key={item.topic.standardItemLink.slug}
              >
                <h2>
                  {item.cmsTopic?.name! || (
                    <SkeletonLoader
                      size={{ height: 'h-8' }}
                      randomSizes={{
                        width: ['w-1/2', 'w-7/12', 'w-3/4'],
                      }}
                    />
                  )}
                </h2>
                <Table<GapAnalysisTopicDRTableRow>
                  {...{
                    columns,
                    data: item.data,
                    getSortedRowModel: getSortedRowModel(),
                    getCellClassName,
                    initialState: {
                      sorting: [
                        {
                          id: 'name',
                          desc: false,
                        },
                      ],
                    },
                  }}
                />
              </div>
            );
          })
      )}
    </div>
  );
}

function aggregateTopicWithCmsTopicAndData({topic, cmsData, navigate, t, isIndicatorCollectFeatureEnabled}  : {
  topic: GapAnalysisSummary_TopicFragment,
  cmsData: { topics: (GapAnalysisSummary_Topic_TreeFragment | null)[] } | undefined,
  navigate: ReturnType<typeof useNavigate>,
  t: ReturnType<typeof useTranslation>['t']
  isIndicatorCollectFeatureEnabled: boolean
}): { topic: GapAnalysisSummary_TopicFragment, cmsTopic?: GapAnalysisSummary_Topic_TreeFragment, data: GapAnalysisTopicDRTableRow[] } {
  const cmsFilteredTopics =
    cmsData?.topics.filter(
      (topic): topic is NonNullable<typeof topic> => topic !== null,
    ) || [];
  const data = prepareData(
    topic.disclosureRequirements,
    cmsFilteredTopics,
    navigate,
    t
  );
  const cmsTopic = cmsFilteredTopics.find(
    (item) => item.slug === topic.standardItemLink.slug,
  );
  return { topic, cmsTopic, data };
}
