import { LoaderFullscreen } from '../../../components/layout/Loader';
import React from 'react';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { SimpleMaterialityMatrix } from '../../../components/project/publications/simpleMateriality/financialByStakeholderSegmentsMatrix/SimpleMaterialityMatrix';
import { MatrixProvider } from '../../../components/project/publications/doubleMateriality/MatrixContext';
import { withProjectContext } from '../../../providers/withProjectContext';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import { Hero } from '../../../components/generic/Hero';
import headerIllustration from '../../../assets/images/illustrations/diagnostic/diag-header.png';
import { useTranslation } from '@hooks/useTranslation';

export const MaterialityMatrixScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();
    const { t } = useTranslation();

    if (projectContext?.isLoadingEnterprise) {
      return <LoaderFullscreen />;
    }

    return (
      <>
        <PageTitle title="Matrice de matérialité" />
        <Breadcrumb />
        <Hero
          title={t('screen.materiality_matrix.title')}
          subtitle={t('screen.materiality_matrix.subtitle')}
          illustration={
            <img className="max-h-72" src={headerIllustration} alt="" />
          }
        />
        <div className="p-8 flex flex-col gap-8 main-content w-full">
          <MatrixProvider initialStakeholderSegments={[]}>
            <SimpleMaterialityMatrix />
          </MatrixProvider>
        </div>
      </>
    );
  },
  { requiredCompany: true },
);
