import React from 'react';
import {
  StakeStandardTopicsPickerModal_Topic_Level1Fragment,
  StakeStandardTopicsPickerModal_TopicFragment,
  useStakeStandardTopicsPickerModal_TopicsQuery,
} from '../../../../graphql/cms/generated';
import { useIsTopicOrSubtopicSelected } from './useIsTopicOrSubtopicSelected';
import { StandardTopicTag } from './StandardTopicTag';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { useTranslation } from '@hooks/useTranslation';
import {
  NotCoveredStandardsFragment,
  StandardEnum,
} from '../../../../graphql/generated';
import { filterEmptyDocuments } from '../../../cms/utils';
import { ChevronDownIcon, InfoIcon } from '../../../icons';
import clsx from 'clsx';
import { JustifyStandardTopicNotCoveredButton } from '../../audit/justification/type/JustifyStandardTopicNotCoveredButton';

// Explicit list of Standards to check for not covered topics
const standardsToCheckForNotCoveredTopics = [StandardEnum.Csrd];

export function NotCoveredStandards({
  referential,
}: {
  referential: NotCoveredStandardsFragment;
}) {
  const selectedTopicIds: string[] =
    referential?.pillars.flatMap(
      (pillar) => pillar.stakes.flatMap((stake) => stake.topicIds || []) || [],
    ) || [];

  // Check if the company standards have standard in the list of standards to check
  const companyStandards =
    referential?.company.reports?.map((report) => report.standard) || [];
  const standardsToCheck = companyStandards.filter((standard) =>
    standardsToCheckForNotCoveredTopics.includes(standard),
  );

  const standardsHavingTopicsNotCovered = standardsToCheck.map((standard) => (
    <NotCoveredStandardTopicsTags
      key={standard}
      selectedTopicIds={selectedTopicIds}
      standardEnum={standard}
      referentialId={referential.id}
    />
  ));

  return <div className="space-y-2">{standardsHavingTopicsNotCovered}</div>;
}

export function NotCoveredStandardTopicsTags({
  selectedTopicIds,
  standardEnum,
  referentialId,
}: {
  selectedTopicIds: string[];
  standardEnum: StandardEnum;
  referentialId: string;
}) {
  const { t, i18n } = useTranslation();

  const cmsQuery = useStakeStandardTopicsPickerModal_TopicsQuery({
    variables: {
      locale: i18n.language,
      standardSlug: standardEnum,
    },
    client: cmsClient,
    fetchPolicy: 'cache-and-network',
  });

  const [isExpanded, setIsExpanded] = React.useState(false);

  const topics = cmsQuery.data?.topics.filter(filterEmptyDocuments) || [];
  const notCoveredTopics = getNotCoveredTopics(topics, selectedTopicIds);

  // Everything is covered
  if (notCoveredTopics.length === 0) {
    return null;
  }

  return (
    <div className="space-y-2 border border-blue-900 rounded-xl p-4">
      <div className="flex items-center justify-between">
        <h6 className="flex items-center gap-2 text-blue-900">
          <InfoIcon />
          {t('referential.not_covered_topics', {
            topicsCount: notCoveredTopics.length,
            standard: standardEnum,
          })}
        </h6>
        <JustifyStandardTopicNotCoveredButton referentialId={referentialId} />
      </div>
      {isExpanded && (
        <div className="flex items-center flex-wrap gap-1">
          {notCoveredTopics.map((topic) => (
            <StandardTopicTag topic={topic} key={topic.documentId} />
          ))}
        </div>
      )}

      <button
        className="tertiary small"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? t('global:close') : t('global:expand')}
        <ChevronDownIcon
          className={clsx('ml-1', { 'rotate-180': isExpanded })}
        />
      </button>
    </div>
  );
}

// Detect when one element of topic tree is not selected
function getNotCoveredTopics(
  topics: StakeStandardTopicsPickerModal_Topic_Level1Fragment[],
  selectedTopicIds: string[],
): StakeStandardTopicsPickerModal_TopicFragment[] {
  const notCoveredTopics: StakeStandardTopicsPickerModal_TopicFragment[] = [];
  for (const topic of topics) {
    const isTopicSelected = selectedTopicIds.includes(topic.documentId);
    if (isTopicSelected) {
      continue;
    }

    const isTopicSelectedOrHasChildSelected = useIsTopicOrSubtopicSelected(
      topic,
      selectedTopicIds,
    );
    if (!isTopicSelectedOrHasChildSelected) {
      notCoveredTopics.push(topic);
    } else {
      for (const subtopic of topic.children.filter(filterEmptyDocuments)) {
        const isSubtopicSelected =
          subtopic && selectedTopicIds.includes(subtopic.documentId);
        if (isSubtopicSelected) {
          continue;
        }

        if (
          subtopic &&
          !useIsTopicOrSubtopicSelected(subtopic, selectedTopicIds)
        ) {
          notCoveredTopics.push(subtopic);
        } else {
          for (const subsubtopic of subtopic.children) {
            const isSubsubtopicSelected =
              subsubtopic && selectedTopicIds.includes(subsubtopic.documentId);
            if (isSubsubtopicSelected) {
              continue;
            }

            subsubtopic && notCoveredTopics.push(subsubtopic);
          }
        }
      }
    }
  }
  return notCoveredTopics;
}
