import {
  BaseIroItemListFragment,
  CompanyFieldsFragment,
} from '../../../../graphql/generated';
import { IroDetailRow } from './iroDetailRow';
import { generatePath, Link } from 'react-router-dom';
import { AppRoutes } from '../../../../screens/AppRoutes';
import { EditIcon } from '../../../icons';
import React from 'react';
import { useTranslation } from '@hooks/useTranslation';

function isMaterial(
  iro: BaseIroItemListFragment,
  company: CompanyFieldsFragment,
): boolean | null {
  if (isFinancialIro(iro.dtype)) {
    if (!company.financialMaterialityThreshold) return null;
    return (iro.score || 0) >= company.financialMaterialityThreshold;
  }
  if (!company.impactMaterialityThreshold) return null;
  return (iro.score || 0) >= company.impactMaterialityThreshold;
}

function isFinancialIro(typename: string | undefined) {
  return !(typename === 'RiskIro' || typename === 'OpportunityIro');
}

export const transformDataToIroList = (
  response: BaseIroItemListFragment[],
  company: CompanyFieldsFragment,
): IroDetailRow[] => {
  const { t } = useTranslation();
  return response.map((iro) => {
    if (iro.stake?.pillar) {
      return {
        stake: iro.stake,
        iro: iro,
        evaluation: {
          score: iro.score,
          isMaterial: isMaterial(iro, company),
        },
        valueChain: {
          position: iro.valueChainItem,
          organizationUnits: iro.organizationalUnits,
        },
        actions: [
          <Link
            key={`iro-evaluation-link-${iro.id}`}
            className="text-gray-300 hover:text-gray-900"
            to={generatePath(AppRoutes.IroRating, {
              pillarId: iro.stake.pillar.id,
              stakeId: iro.stake.id,
            })}
            aria-label={t('iro.summary.table.actions.evaluation.label')}
          >
            <EditIcon />
          </Link>,
        ],
      };
    }
    return {} as IroDetailRow;
  });
};
