import React from 'react';
import {
  IndicatorItem_IndicatorFragment,
  useIndicatorValueForm_LatestIndicatorValuesQuery,
} from '../../../../../../../graphql/generated';
import { TableSkeletonLoader } from '../../../../../../generic/loader/TableSkeletonLoader';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';
import { TypeIndicatorValueForm } from './IndicatorValueForm';
import { EditIndicatorButton } from '../../edit/EditIndicatorButton';

type Props = {
  indicator: IndicatorItem_IndicatorFragment;
  enterpriseId?: string;
};

export const IndicatorValueByDimensionForm = ({
                                                indicator,
                                                enterpriseId,
                                              }: Props) => {
  const { t, translateProperty } = useTranslation();
  const { data, loading } =
    useIndicatorValueForm_LatestIndicatorValuesQuery({
      variables: {
        indicatorId: indicator.id,
        enterpriseId: enterpriseId ?? undefined,
      },
    });

  if (loading) {
    return <TableSkeletonLoader columns={3} rows={5}  randomSizes={{
      width: [
        'w-1/2',
        'w-7/12',
        'w-3/4',
        'w-9/12',
        'w-10/12',
        'w-11/12',
        'w-12/12',
      ],
    }}/>;
  }

  const dimensions = indicator.dimensions ?? [];
  const dimension1Items = indicator.dimensionItems.filter(item => item.dimension?.id === dimensions[0]?.id) || [];
  const dimension2Items = indicator.dimensionItems.filter(item => item.dimension?.id === dimensions[1]?.id) || [];
  const indicatorValues = data?.latestIndicatorValues || [];

  // According to dimension items number, show dimension1 or dimension2 the most the first column
  const column1 = dimension1Items.length > dimension2Items.length ? { dimension: dimensions[0], items: dimension1Items} : { dimension: dimensions[1], items: dimension2Items};
  const column2 = dimension1Items.length > dimension2Items.length ? { dimension: dimensions[1], items: dimension2Items} : { dimension: dimensions[0], items: dimension1Items};

  return (
    <table className={'w-fit min-w-96'}>
      <thead>
      <tr>
        <th className="px-4 py-2">
          <div className="flex items-center gap-4">
            {translateProperty(column1.dimension, 'name')}
          </div>
        </th>
        {column2.dimension &&
          <th className="px-4 py-2">
            <div className="flex items-center gap-4">
              {translateProperty(column2.dimension, 'name')}
            </div>
          </th>}
        <th className="px-4 py-2">{/* todo use unit family as label */}</th>
      </tr>
      </thead>
      <tbody>
      {column1.items.length > 0 && column1.items.map((item1, dimension1Index) => {
        return (
          <React.Fragment key={item1.id}>
            {column2.items.length > 0 ? (
              column2.items.map((item2, index) => {
                const displayDimLabel = (index === 0 || column2.items.length === 1)
                return <tr key={`${item1.id}-${item2.id}`}>
                  {index ===0 &&
                    (<td className={clsx(
                      'px-4 py-2 align-center',
                      dimension1Index + 1 === column1.items.length && 'rounded-bl-2xl'
                    )}
                         rowSpan={column2.items.length}>
                      {displayDimLabel && translateProperty(item1, 'name')}
                    </td>)
                  }
                  <td className=" px-4 py-2 !border-l-0 !rounded-bl-none">
                    {translateProperty(item2, 'name')}
                  </td>
                  <td className=" px-4 py-2">
                    {renderIndicatorValueForm(
                      indicator,
                      indicatorValues,
                      item1.id,
                      item2.id,
                      enterpriseId,
                    )}
                  </td>
                </tr>;
              })
            ) : (
              <tr key={item1.id}>
                <td className=" px-4 py-2">
                  {translateProperty(item1, 'name')}
                </td>
                <td className=" px-4 py-2">
                  {renderIndicatorValueForm(
                    indicator,
                    indicatorValues,
                    item1.id,
                    undefined,
                    enterpriseId,
                  )}
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      }) || <tr>
        <td colSpan={3}>
          <div className={'flex flex-col m-5 text-center gap-5'}>
            {t(
              'translation:indicator_collect.disclosure_requirement.indicator.emptyState.needConfiguration',
            )}
            <EditIndicatorButton initialOpenState={true} indicator={indicator} className={"small"}/>
          </div>
        </td>
      </tr>}
      </tbody>
    </table>
  );
};

const renderIndicatorValueForm = (
  indicator: IndicatorItem_IndicatorFragment,
  indicatorValues: any[],
  dimension1Id: string,
  dimension2Id?: string,
  enterpriseId?: string
) => {
  let indicatorValue = indicatorValues.find(
    (iv) =>
      iv.dimensionItems.some((item: any) => item.id === dimension1Id) &&
      (!dimension2Id ||
        iv.dimensionItems.some((item: any) => item.id === dimension2Id))
  );

  if (!indicatorValue) {
    indicatorValue = {
      __typename: indicator.type,
      enterprise: enterpriseId ? { id: enterpriseId } : undefined,
      dimensionItems: [{ id: dimension1Id }, { id: dimension2Id }]
    }
  }
  return <TypeIndicatorValueForm {...{ indicatorValue, indicator }}/>
};
