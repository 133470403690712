import React from 'react';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { Referential } from '../../../components/project/stakes/Referential';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import { withProjectContext } from '../../../providers/withProjectContext';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { Hero } from '../../../components/generic/Hero';
import { useTranslation } from '@hooks/useTranslation';
import { ChooseProjectTemplateModal } from '../../../components/project/project/ChooseProjectTemplate/ChooseProjectTemplateModal';
import { useModal } from '../../../components/layout/Modal';
import { PlusIcon } from '../../../components/icons';
import resultIllusatration from '../../../assets/images/illustrations/result.png';
import { IllustratedExplanationMessage } from '../../../components/generic/IllustratedExplanationMessage';

export const ReferentialScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();
    const { t } = useTranslation();
    const modal = useModal();
    const openReferentialCreateModal = () =>
      modal.openModalWithComponent(
        <ChooseProjectTemplateModal />,
        '',
        true,
        false,
        'w-4/5',
      );

    return (
      <>
        <PageTitle title="Enjeux" />
        <Breadcrumb />
        {projectContext?.enterprise?.referential && (
          <Hero
            title={t('referential.header.title')}
            subtitle={t('referential.header.description')}
          />
        )}
        <div className="p-8 w-full">
          {(projectContext?.isLoadingEnterprise && <LoaderFullscreen />) ||
            (projectContext?.enterprise?.referential && (
              <Referential
                referentialId={projectContext.enterprise.referential?.id}
              />
            )) || (
              <>
                <IllustratedExplanationMessage
                  illustration={<img src={resultIllusatration} alt="" />}
                  title={t('referential.emptyState.title')}
                  description={t('referential.emptyState.description')}
                >
                  <button
                    className="primary purple"
                    onClick={openReferentialCreateModal}
                  >
                    <PlusIcon className="w-4 h-4" />
                    {t('referential.emptyState.cta')}
                  </button>
                </IllustratedExplanationMessage>
              </>
            )}
        </div>
      </>
    );
  },
  { requiredCompany: true },
);
