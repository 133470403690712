import React from 'react';

export const Hero = ({
  title,
  subtitle,
  illustration,
  children,
}: {
  title: string;
  subtitle: string;
  illustration?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <div className="bg-blue-50">
      <div className="main-content">
        <div className="flex items-center justify-between py-8 min-h-40 gap-8">
          <div className="space-y-2 w-2/3">
            <h1>{title}</h1>
            <div className="">{subtitle}</div>
          </div>
          {illustration && <div>{illustration}</div>}
        </div>
        {children}
      </div>
    </div>
  );
};
