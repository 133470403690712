import React from 'react';
import {
  JustificationType,
  useJustifyReportDisclosureRequirementButtonQuery,
} from '../../../../../graphql/generated';
import { JustifyButton, JustifyButtonStyle } from '../JustifyButton';

export const JustifyReportDisclosureRequirementButton = ({
  reportDisclosureRequirementId,
}: {
  reportDisclosureRequirementId: string;
}) => {
  const type = JustificationType.ReportDisclosureRequirement;

  const query = useJustifyReportDisclosureRequirementButtonQuery({
    variables: {
      type,
      id: reportDisclosureRequirementId,
    },
  });

  const latestJustificationId =
    query.data?.reportDisclosureRequirementJustifications?.edges[0]?.node.id;

  return (
    <JustifyButton
      type={type}
      entityId={reportDisclosureRequirementId}
      latestJustificationId={latestJustificationId}
      callback={query.refetch}
      style={JustifyButtonStyle.MessageIcon}
    />
  );
};
