import React from 'react';
import clsx from 'clsx';
import {
  getBgColorFromColorStrength,
  getTextColorFromColorStrength,
  GradientDropdownStrength,
} from './dropdown/GradientDropdown';

export function GradientTag({
  strength,
  name,
}: {
  strength: GradientDropdownStrength;
  name: string;
}) {
  return (
    <div
      className={clsx(
        'block truncate rounded-md p-1 font-bold w-full',
        getBgColorFromColorStrength(strength),
        getTextColorFromColorStrength(strength),
      )}
    >
      {name}
    </div>
  );
}
