import React from 'react';
import gapAnalysis from '../../../assets/images/illustrations/gapAnalysis/GapAnalysis.svg';
import { useTranslation } from '@hooks/useTranslation';
import { Hero } from '../../../components/generic/Hero';

export const GapAnalysisHero = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <Hero
      title={t('gap_analysis.hero.title')}
      subtitle={t('gap_analysis.hero.description')}
      illustration={
        <img className="w-full" src={gapAnalysis} alt="Gap Analysis" />
      }
    >
      {children}
    </Hero>
  );
};
