import React from 'react';
import { filterEmptyDocuments } from '../../../../cms/utils';
import { BlockWithDatapointsRenderer } from '../BlockWithDatapointsRenderer';
import { BlocksRenderer } from '../../../../cms/renderers/BlocksRenderer';
import { Footnotes } from './Footnotes';
import { DisclosureRequirementLevel3Content } from './DisclosureRequirementLevel3Content';
import { DisclosureRequirement_DrLevel2Fragment } from '../../../../../graphql/cms/generated';

export const DisclosureRequirementLevel2Content = ({
  drLevel2,
}: {
  drLevel2: DisclosureRequirement_DrLevel2Fragment;
}) => {
  const level3Dr = drLevel2.dr_level_3?.filter(filterEmptyDocuments) || [];
  const footnotes = drLevel2.footnotes?.filter(filterEmptyDocuments) || [];
  const datapoints = drLevel2.datapoints.filter(filterEmptyDocuments);

  return (
    <div>
      <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
        <div className="space-y-4">
          {datapoints.length > 0 ? (
            <BlockWithDatapointsRenderer
              content={drLevel2.content}
              datapoints={datapoints.map((d) => d.slug || '')}
            />
          ) : (
            <BlocksRenderer content={drLevel2.content} />
          )}
          {footnotes.length > 0 && <Footnotes footnotes={footnotes} />}
        </div>
      </div>
      <div className="space-y-2 pl-4">
        {level3Dr.map((drLevel3, index) => (
          <DisclosureRequirementLevel3Content
            drLevel3={drLevel3}
            key={`dr_level3_${index}`}
          />
        ))}
      </div>
    </div>
  );
};
