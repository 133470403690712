import React from 'react';
import { JustificationType } from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { JustificationFormModal } from './JustificationFormModal';
import { JustificationIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';

export enum JustifyButtonStyle {
  MessageIcon = 'MessageIcon',
  WithTagLabel = 'WithTagLabel',
}

export const JustifyButton = ({
  type,
  entityId,
  latestJustificationId,
  style,
  callback,
}: {
  type: JustificationType;
  entityId: string;
  latestJustificationId: string | null | undefined;
  style: JustifyButtonStyle;
  callback?: () => void;
}) => {
  const modal = useModal();
  const { t } = useTranslation();

  const openJustificationModal = () => {
    modal.openModalWithComponent(
      <JustificationFormModal
        type={type}
        entityId={entityId}
        latestJustificationId={latestJustificationId}
        callback={callback}
      />,
      t('audit.justification.modal_title'),
      false,
      true,
      'w-1/3',
      'p-0',
    );
  };

  return (
    <div className="flex items-center gap-1">
      {style === JustifyButtonStyle.WithTagLabel && (
        <div
          className={clsx(
            'tag font-bold',
            latestJustificationId ? 'green' : 'gray',
          )}
        >
          {latestJustificationId
            ? t('audit.justification.button_done')
            : t('audit.justification.button_todo')}
        </div>
      )}
      <button className="tertiary p-1" onClick={openJustificationModal}>
        {latestJustificationId ? (
          <JustificationIcon className="w-5 h-5 text-blue-500" />
        ) : (
          <JustificationIcon className="w-5 h-5 text-gray-300" />
        )}
      </button>
    </div>
  );
};
