import React from 'react';
import './index.css';
import 'headless-react-datepicker/dist/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  FirebaseAppContext,
  initializeApp,
} from './providers/FirebaseProvider';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { ApiApolloProvider } from './providers/ApiApolloProvider';
import mixpanel from 'mixpanel-browser';
import 'i18n';
import { createRoot } from 'react-dom/client';

if (process.env.REACT_APP_SENTRY_DSN) {
  const integrations = [];
  if (process.env.REACT_APP_SENTRY_ENABLE_BROWSER_TRACING) {
    integrations.push(Sentry.browserTracingIntegration());
  }
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  const tracesSampleRate = parseFloat(
    process.env.REACT_APP_SENTRY_SAMPLE_RATE || '1.0',
  );

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations,
    tracesSampleRate,
    environment: process.env.NETLIFY_CONTEXT,
  });
}

if (process.env.MIXPANEL_TOKEN) {
  mixpanel.init(process.env.MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV === 'development',
  });
}

const firebaseApp = initializeApp();

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <html lang="fr" />
      </Helmet>
      <FirebaseAppContext.Provider value={firebaseApp}>
        <ApiApolloProvider>
          <App />
        </ApiApolloProvider>
      </FirebaseAppContext.Provider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
