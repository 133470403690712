import { useTranslation } from "@hooks/useTranslation";
import { NoData } from "../../../generic/NoData";
import { DoughnutChart } from "../../../generic/chart/DoughnutChart";
import React from "react";

export function AvailableDatapointsVsSelectedDatapointsDoughnut({
                                                                  availableDatapointsCount,
                                                                  selectedDatapointsCount
                                                                }: {
  availableDatapointsCount: number;
  selectedDatapointsCount: number;
}) {
  const { t } = useTranslation();

  if (availableDatapointsCount === 0 && selectedDatapointsCount === 0) {
    return <NoData text={t("global:noResult")} actions={[]}></NoData>;
  }

  return (
    <DoughnutChart
      data={[
        availableDatapointsCount,
        selectedDatapointsCount - availableDatapointsCount
      ]}
      labels={[
        t(
          "gap_analysis.synthesis.availableDatapointsOverSelectedChart.availableDatapoints"
        ),
        t(
          "gap_analysis.synthesis.availableDatapointsOverSelectedChart.selectedDatapoints"
        )
      ]}
      title={t(
        "gap_analysis.synthesis.availableDatapointsOverSelectedChart.title"
      )}
    />
  );
}