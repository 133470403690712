import { DisclosureRequirementMdrLevel3 } from './DisclosureRequirementMdrLevel3';
import React from 'react';
import {
  DisclosureRequirement_MdrLevel2Fragment,
  DisclosureRequirement_MdrLevel3Fragment,
} from '../../../../../graphql/cms/generated';
import { filterEmptyDocuments } from '../../../../cms/utils';
import { BlocksRenderer } from '../../../../cms/renderers/BlocksRenderer';

export const DisclosureRequirementMdrLevel2 = ({
  mdrLevel2,
}: {
  mdrLevel2: DisclosureRequirement_MdrLevel2Fragment;
}) => {
  const mdrLevel3: DisclosureRequirement_MdrLevel3Fragment[] =
    mdrLevel2.mdr_level_3?.filter(filterEmptyDocuments) || [];
  return (
    <div className="">
      <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
        <div className="space-y-2 hover:bg-gray-50">
          <BlocksRenderer content={mdrLevel2.content} />
        </div>
      </div>
      <div className="space-y-2 pl-4">
        {mdrLevel3.map((mdrLevel3, index) => (
          <DisclosureRequirementMdrLevel3
            mdrLevel3={mdrLevel3}
            key={`mdr_level3_${index}`}
          />
        ))}
      </div>
    </div>
  );
};
