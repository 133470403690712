import React, { useState } from 'react';
import { useModal } from '../../layout/Modal';
import { Loader } from '../../generic/Loader';
import {
  CreateNoteButton_NotebookFragment,
  useCreateNoteButton_NoteCreateMutation,
} from '../../../graphql/generated';
import { UpdateNoteModal } from './UpdateNoteModal';
import { EditIcon } from '../../icons';

export function CreateNoteButton({
  notebook,
  className = '',
}: {
  notebook: CreateNoteButton_NotebookFragment;
  className?: string;
}) {
  const modal = useModal();
  const [createNoteMutation] = useCreateNoteButton_NoteCreateMutation();
  const [isLoading, setIsLoading] = useState(false);

  const createNote = () => {
    setIsLoading(true);
    createNoteMutation({
      variables: {
        input: {
          notebook: {
            id: notebook.id,
          },
          title: 'Nouvelle note',
        },
      },
    })
      .then((res) => {
        if (res.data?.noteCreate) {
          modal.openModalWithComponent(
            <UpdateNoteModal note={res.data?.noteCreate} />,
            null,
            false,
            false,
            'w-3xl',
            'p-0',
          );
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button
      disabled={isLoading}
      onClick={() => createNote()}
      className={className}
    >
      {isLoading ? <Loader /> : <EditIcon className="w-4 h-4" />}
      Créer une note
    </button>
  );
}
