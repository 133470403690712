import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { LoaderFullscreen } from '../../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import {
  StakeIroDetail_NegativeImpactIroFragment,
  StakeIroDetail_OpportunityIroFragment,
  StakeIroDetail_PositiveImpactIroFragment,
  StakeIroDetail_RiskIroFragment,
  SurveyLanguage,
  useStakeIroDetailQuery,
} from '../../../../graphql/generated';
import { IroRiskCard } from './IroCard/IroRiskCard';
import { IroPositiveImpactCard } from './IroCard/IroPositiveImpactCard';
import { IroNegativeImpactCard } from './IroCard/IroNegativeImpactCard';
import { IroOpportunityCard } from './IroCard/IroOpportunityCard';
import { tidyIros } from '../../../project/iro/tidyIros';
import { ProgressBarWithScale } from '../../../generic/ProgressBarWithScale';
import LanguageDropdown from '../../../form/LanguageDropdown';
import { ImpactMaterialityScore } from '../../../project/iro/evaluation/scores/ImpactMaterialityScore';
import { FinancialMaterialityScore } from '../../../project/iro/evaluation/scores/FinancialMaterialityScore';
import { StakeMaterialityReason } from './StakeMaterialityReason';

export function StakeIroDetail({ stakeId }: { stakeId: string }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<SurveyLanguage>(
    i18n.language as SurveyLanguage,
  );
  const { loading, data, error } = useStakeIroDetailQuery({
    variables: {
      stakeId: stakeId,
    },
    fetchPolicy: 'network-only',
  });

  const { risks, positiveImpacts, negativeImpacts, opportunities } = tidyIros<
    StakeIroDetail_RiskIroFragment,
    StakeIroDetail_OpportunityIroFragment,
    StakeIroDetail_PositiveImpactIroFragment,
    StakeIroDetail_NegativeImpactIroFragment
  >(data?.iros);

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (error) {
    return <MessageBox type={MessageBoxType.Error}>{error.message}</MessageBox>;
  }
  return (
    <div className="p-4 space-y-8 divide-y divide-gray-100">
      <div className="space-y-2">
        <h5>{t('stake.detailModal.materiality.impactScore')}</h5>
        <div className="flex items-center gap-4">
          <div className="w-7/12">
            <ProgressBarWithScale
              percentageValue={
                (data?.stakeMateriality?.impactMaterialityScore ?? 0) / 4
              }
              displayLevel={true}
            />
          </div>
          <div className="w-5/12 flex justify-end">
            {data?.stakeMateriality && (
              <ImpactMaterialityScore stake={data?.stakeMateriality} />
            )}
          </div>
        </div>
        <h5>{t('stake.detailModal.materiality.financialScore')}</h5>
        <div className="flex items-center gap-4">
          <div className="w-7/12">
            <ProgressBarWithScale
              percentageValue={
                (data?.stakeMateriality?.financialMaterialityScore ?? 0) / 4
              }
              displayLevel={true}
            />
          </div>
          <div className="w-5/12 flex justify-end">
            {data?.stakeMateriality && (
              <FinancialMaterialityScore stake={data?.stakeMateriality} />
            )}
          </div>
        </div>
      </div>
      {data?.stakeMateriality && (
        <div className="pt-8">
          <StakeMaterialityReason stake={data?.stakeMateriality} />
        </div>
      )}
      <div>
        <div className="flex items-center justify-between pt-8">
          <h5>{t('screen.iro.impacts_risks_and_opportunities')}</h5>
          <LanguageDropdown
            currentLanguage={language}
            onUpdate={(language) => setLanguage(language)}
          />
        </div>
        <div className="space-y-4 divide-y divide-gray-100">
          <div className="space-y-2 pt-4">
            <div className="flex items-center justify-between gap-4">
              <IroTitle>{t('iro.types.NEGATIVE_IMPACT')}</IroTitle>
            </div>
            <div className="space-y-2">
              {negativeImpacts.length === 0 && <NoIro />}
              {negativeImpacts.map((iro) => (
                <IroNegativeImpactCard
                  key={`stakeIroDetailIroNegativeImpactCard-${iro.id}`}
                  {...{ iro, language }}
                />
              ))}
            </div>
          </div>
          <div className="space-y-2 pt-4">
            <div className="flex items-center justify-between gap-4">
              <IroTitle>{t('iro.types.POSITIVE_IMPACT')}</IroTitle>
            </div>
            <div className="space-y-2">
              {positiveImpacts.length === 0 && <NoIro />}
              {positiveImpacts.map((iro) => (
                <IroPositiveImpactCard
                  key={`stakeIroDetail_IroPositiveImpactCard-${iro.id}`}
                  {...{ iro, language }}
                />
              ))}
            </div>
          </div>
          <div className="space-y-2 pt-4">
            <div className="flex items-center justify-between gap-4">
              <IroTitle>{t('iro.types.RISK')}</IroTitle>
            </div>
            <div className="space-y-2">
              {risks.length === 0 && <NoIro />}
              {risks.map((iro) => (
                <IroRiskCard
                  key={`stakeIroDetail_IroRiskCard-${iro.id}`}
                  {...{ iro, language }}
                />
              ))}
            </div>
          </div>
          <div className="space-y-2 pt-4">
            <div className="flex items-center justify-between gap-4">
              <IroTitle>{t('iro.types.OPPORTUNITY')}</IroTitle>
            </div>
            <div className="space-y-2">
              {opportunities.length === 0 && <NoIro />}
              {opportunities.map((iro) => (
                <IroOpportunityCard
                  key={`stakeIroDetail_IroOpportunityCard-${iro.id}`}
                  {...{ iro, language }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function IroTitle({ children }: { children: React.ReactNode }) {
  return (
    <div className="font-bold first-letter:capitalize grow">{children}</div>
  );
}

function NoIro() {
  const { t } = useTranslation();
  return (
    <div className="text-gray-500 text-center italic text-sm">
      {t('global:none')}
    </div>
  );
}
