import { useTranslation } from "@hooks/useTranslation";
import { NoData } from "../../../generic/NoData";
import { DoughnutChart } from "../../../generic/chart/DoughnutChart";
import React from "react";

export function VoluntaryDatapointsVsUnvoluntaryDatapointsDoughnut({
                                                                  voluntaryDatapointsCount,
                                                                  unvoluntaryDatapointsCount
                                                                }: {
  voluntaryDatapointsCount: number;
  unvoluntaryDatapointsCount: number;
}) {
  const { t } = useTranslation();

  if (voluntaryDatapointsCount === 0 && unvoluntaryDatapointsCount === 0) {
    return <NoData text={t("global:noResult")} actions={[]}></NoData>;
  }

  return (
    <DoughnutChart
      data={[
        voluntaryDatapointsCount,
        unvoluntaryDatapointsCount
      ]}
      labels={[
        t(
          "gap_analysis.synthesis.voluntaryDatapointsOverUnvoluntaryChart.voluntaryDatapoints"
        ),
        t(
          "gap_analysis.synthesis.voluntaryDatapointsOverUnvoluntaryChart.unvoluntaryDatapoints"
        )
      ]}
      title={t(
        "gap_analysis.synthesis.voluntaryDatapointsOverUnvoluntaryChart.title"
      )}
    />
  );
}