import React, { ReactNode } from 'react';

export const NoData = ({
  text,
  actions,
}: {
  text: ReactNode;
  actions: { key: string; component: ReactNode }[];
}) => {
  return (
    <div className="bg-white flex flex-col items-center justify-center gap-4 text-gray-900 text-center p-8 rounded-xl border border-gray-100 shadow-sm">
      {text}
      {actions.length > 0 && (
        <div className="flex items-center gap-2">
          {actions.map(({ key, component }) => (
            <div key={key}>{component}</div>
          ))}
        </div>
      )}
    </div>
  );
};
