import { useEffect, useState } from 'react';
import { DimensionItemsList } from './DimensionItemsList';
import {
  DimensionItemDropdown_StrapiQuery,
  useDimensionItemDropdown_StrapiLazyQuery,
} from 'graphql/cms/generated';
import { cmsClient } from 'graphql/clients/cmsClient';
import { useTranslation } from '@hooks/useTranslation';
import {
  IndicatorValueByDimensionForm_DimensionFragment,
  SurveyLanguage,
} from 'graphql/generated';

type ConfigureDimensionItemsProps = {
  dimension1: IndicatorValueByDimensionForm_DimensionFragment;
  dimension2?: IndicatorValueByDimensionForm_DimensionFragment;
  language?: SurveyLanguage;
};

export const ConfigureDimensionItems = ({dimension1, dimension2, language}: ConfigureDimensionItemsProps) => {
    const { i18n } = useTranslation();
    const [query] = useDimensionItemDropdown_StrapiLazyQuery({
      client: cmsClient,
    });
    const [availableDimensionItems1, setAvailableDimensionItems1] = useState<
    DimensionItemDropdown_StrapiQuery['dimensionItems']
  >([]);
  const [availableDimensionItems2, setAvailableDimensionItems2] = useState<
    DimensionItemDropdown_StrapiQuery['dimensionItems']
  >([]);

  const fetchStandardItems = async (slug: string) => {
    const response = await query({
      variables: {
        locale: 'fr' /* assuming fr is always filled, translated after to keep fallback otherwise */,
        filters: {
          dimension: {
            slug: {
              eq: slug,
            },
          },
        },
      },
    });
    return response.data?.dimensionItems;
  };
  useEffect(() => {
    if (i18n.language && dimension1.standardItemLink?.slug) {
      fetchStandardItems(dimension1.standardItemLink.slug).then((items) => {
        if (items) {
          setAvailableDimensionItems1(items);
        }
      });
    }
  }, [dimension1, i18n.language]);
  useEffect(() => {
    if (i18n.language && dimension2?.standardItemLink?.slug) {
      fetchStandardItems(dimension2.standardItemLink.slug).then((items) => {
        if (items) {
          setAvailableDimensionItems2(items);
        }
      });
    } 
  }, [dimension2, i18n.language]);

  return <div>
    <DimensionItemsList
      dimension={dimension1}
      inputName="dimension1"
      availableItems={availableDimensionItems1}
      language={language}
    />
    {dimension2 && (
      <DimensionItemsList
        dimension={dimension2}
        inputName="dimension2"
        availableItems={availableDimensionItems2}
        language={language}
      />
    )}
  </div>

};
