import React from 'react';
import { IndicatorItem_IndicatorFragment } from '../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { ContextualMenu } from '../../../../generic/ContextualMenu';
import { EditIcon, MenuIcon } from '../../../../icons';
import { IndicatorValueFormsForEachOrgUnit } from './indicatorValue/form/IndicatorValueFormsForEachOrgUnit';
import { IndicatorValueByDimensionForm } from './indicatorValue/form/IndicatorValueByDimensionForm';
import { DeleteIndicatorButton } from './delete/DeleteIndicatorButton';
import { IndicatorValueForm } from './indicatorValue/form/IndicatorValueForm';
import { IndicatorValueFormProvider } from './indicatorValue/IndicatorValueFormContext';
import { IndicatorValueFormContainer } from './indicatorValue/IndicatorValueFormContainer';
import { EditIndicatorButton } from './edit/EditIndicatorButton';

export const IndicatorItem = ({
  indicator,
}: {
  indicator: IndicatorItem_IndicatorFragment;
}) => {
  const { t, translateProperty } = useTranslation();

  return (
    <div className="rounded-xl bg-white border border-gray-100 p-4 space-y-2">
      <div className="flex items-start justify-between gap-4">
        <h4>{translateProperty(indicator, 'name')}</h4>
        <ContextualMenu button={<MenuIcon />}>
          <EditIndicatorButton indicator={indicator} icon={<EditIcon />} />
          <DeleteIndicatorButton indicator={indicator} />
        </ContextualMenu>
      </div>
      <div className="flex items-center gap-2">
        <div className="tag purple">
          {t(`enum:indicator.type.${indicator.type}`)}
        </div>
      </div>

      {indicator.splitByOrganizationalUnit ? (
        <IndicatorValueFormsForEachOrgUnit indicator={indicator} />
      ) : (
        <IndicatorValueFormProvider indicator={indicator}>
          <IndicatorValueFormContainer>
            {(indicator.dimensions?.length > 0 && (
              <IndicatorValueByDimensionForm indicator={indicator} />
            )) || <IndicatorValueForm indicator={indicator} />}
          </IndicatorValueFormContainer>
        </IndicatorValueFormProvider>
      )}
    </div>
  );
};
