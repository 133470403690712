import React from 'react';
import {
  IroSummaryFragment,
  IroSummaryScreenScoresFragment,
} from '../../../../graphql/generated';
import { ThemeIcon } from '../../../generic/icon/ThemeIcon';
import { ColorIcon } from '../../../generic/icon/ColorIcon';
import { IroSummaryStakeRow } from './useIroSummaryColumns';

import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from '@hooks/useTranslation';
import { EditIcon } from '../../../icons';
import {
  StandardTopicTag_TopicFragment,
  useIroSummary_TopicsQuery,
} from '../../../../graphql/cms/generated';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { AppRoutes } from '../../../../screens/AppRoutes';

/**
 * Transform IroSummary gql response pillars's stakes, flatmapped
 * and alphabetically sorted
 *
 * @return IroSummaryStakeRow[]
 */
export const transformData = ({
  response,
  scores,
  filters,
  impactMaterialityThreshold = 0,
  financialMaterialityThreshold = 0,
}: {
  response: IroSummaryFragment['pillars'];
  scores: IroSummaryScreenScoresFragment[];
  filters?: { pillarId: string[] } | null;
  impactMaterialityThreshold?: number | null;
  financialMaterialityThreshold?: number | null;
}): IroSummaryStakeRow[] => {
  const { t, i18n, translateProperty } = useTranslation();
  const scoresMap = new Map<string, IroSummaryScreenScoresFragment>();
  scores.forEach((score) => {
    scoresMap.set(score.id, score);
  });

  const allTopicsIds = response
    .flatMap((pillar) => pillar.stakes)
    .flatMap((stake) => stake.topicIds ?? []);
  const topicsQuery = useIroSummary_TopicsQuery({
    client: cmsClient,
    variables: {
      locale: i18n.language,
      topicIds: allTopicsIds,
    },
  });
  const topics: StandardTopicTag_TopicFragment[] =
    (topicsQuery.data?.topics as StandardTopicTag_TopicFragment[]) ?? [];

  function buildScore({
    score,
    materialTreshold,
  }: {
    score?: number | null;
    materialTreshold?: number | null;
  }): { score?: number | null; isMaterial: boolean } {
    if (score && materialTreshold && materialTreshold < score)
      return {
        score,
        isMaterial: true,
      };
    return {
      score: score === 0 ? undefined : score,
      isMaterial: false,
    };
  }

  return response
    .map((pillar) => {
      if (
        filters &&
        filters.pillarId.length > 0 &&
        !filters.pillarId.includes(pillar.id)
      ) {
        return [];
      }
      const icon = (
        <ColorIcon
          // @ts-expect-error same colors
          color={pillar.color}
          icon={<ThemeIcon themeIcon={pillar.icon} />}
        />
      );
      const iconWithBorder = (
        <ColorIcon
          // @ts-expect-error same colors
          color={pillar.color}
          icon={<ThemeIcon themeIcon={pillar.icon} />}
          borderColor={'purple'}
        />
      );

      return pillar.stakes.map((stake) => {
        const {
          id,
          isDisabled,
          persistedFinancialMaterialityScore,
          computedFinancialMaterialityScore,
          persistedImpactMaterialityScore,
          computedImpactMaterialityScore,
        } = stake;
        const score = scoresMap.get(id);
        const financialMaterialityScore =
          score?.financialMaterialityScore ??
          persistedFinancialMaterialityScore ??
          computedFinancialMaterialityScore;
        const impactMaterialityScore =
          score?.impactMaterialityScore ??
          persistedImpactMaterialityScore ??
          computedImpactMaterialityScore;

        return {
          stake: {
            id,
            name: translateProperty(stake, 'name'),
            icon: stake.isDisabled ? icon : iconWithBorder,
            isDisabled: isDisabled ?? false,
          },
          topics: topics.filter((topic) =>
            stake.topicIds?.includes(topic?.documentId || ''),
          ),
          isLoadingTopics: topicsQuery.loading,
          financialMateriality: buildScore({
            score: financialMaterialityScore,
            materialTreshold: financialMaterialityThreshold,
          }),
          impactMateriality: buildScore({
            score: impactMaterialityScore,
            materialTreshold: impactMaterialityThreshold,
          }),
          maxMateriality: buildScore({
            score: Math.max(
              financialMaterialityScore ?? 0,
              impactMaterialityScore ?? 0,
            ),
            materialTreshold: undefined,
          }),
          iros: stake.iros?.filter((iro) => iro != undefined) ?? [],
          actions: [
            <Link
              key={`iro-evaluation-link-${id}`}
              className="text-gray-300 hover:text-gray-900"
              to={generatePath(AppRoutes.IroRating, {
                pillarId: pillar.id,
                stakeId: id,
              })}
              aria-label={t('iro.summary.table.actions.evaluation.label')}
            >
              <EditIcon />
            </Link>,
          ],
        };
      });
    })
    .flatMap((x) => x)
    .sort((a, b) => a.stake.name.localeCompare(b.stake.name));
};
