import React, { useEffect } from 'react';
import {
  EditIndicatorButtonProps,
  EditIndicatorFormValues,
  I18nItem,
} from './editIndicator.types';
import { useTranslation } from '@hooks/useTranslation';
import { useModal } from '../../../../../layout/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { SurveyLanguage } from '../../../../../../graphql/generated';
import {
  ReportDataPointProvider,
  useReportDataPoint,
} from '../../ReportDataPointProvider';
import { EditIndicator } from './EditIndicator';

export const EditIndicatorButton = ({
  indicator,
  icon,
  initialOpenState = false,
  className = 'contextual-menu-item',
}: EditIndicatorButtonProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const dimensions =
    indicator.dimensions?.map((dimension) => dimension.id) || [];
  const getDimensionItems = (dimensionId: string) =>
    indicator.dimensionItems
      .filter((item) => item.dimension?.id === dimensionId)
      .map((item) => ({
        standardItemLink: item.standardItemLink || undefined,
        i18n: item.i18n as unknown as I18nItem[],
      })) || [];

  const dimension1Items = getDimensionItems(dimensions[0]);
  const dimension2Items = getDimensionItems(dimensions[1]);
  const methods = useForm<EditIndicatorFormValues>({
    defaultValues: {
      i18n: [
        {
          language: SurveyLanguage.Fr,
          name: indicator.i18n?.[0]?.name,
        },
        {
          language: SurveyLanguage.En,
          name: indicator.i18n?.[1]?.name,
        },
      ],
      dimension1: {
        items: dimension1Items,
      },
      dimension2: {
        items: dimension2Items,
      },
    },
  });
  const { reportDataPoint } = useReportDataPoint();

  const openModal = () =>
    modal.openModalWithComponent(
      <ReportDataPointProvider {...{ reportDataPoint }}>
        <FormProvider {...methods}>
          <EditIndicator indicator={indicator} />
        </FormProvider>
      </ReportDataPointProvider>,
      t('indicator_collect.disclosure_requirement.indicator.edit.modal.title'),
      false,
      false,
      null,
      'p-0',
    );

  // Open the modal automatically if initialOpenState is true
  useEffect(() => {
    if (initialOpenState) {
      openModal();
    }
  }, [initialOpenState]);

  return (
    <button className={className} onClick={openModal}>
      {icon}
      {t(
        'indicator_collect.disclosure_requirement.indicator.contextualMenu.edit',
      )}
    </button>
  );
};
