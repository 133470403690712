import React, { useRef } from 'react';
import {
  CompanySector,
  EnterpriseCard_EnterpriseFragment,
  EnterpriseStructureType,
  useCreateSubsidiaryEnterpriseMutation,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { EnterpriseInformationForm } from './EnterpriseForm';
import { StringDropdownItem } from '../../../generic/dropdown/StringDropdown.types';

export function CreateSubsidiaryEnterprise({
  onSave,
  enterpriseParent,
}: {
  onSave?: () => void;
  enterpriseParent: EnterpriseCard_EnterpriseFragment;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const formRef = useRef<{ submit: () => void } | null>(null);
  const [createSubsidiary, { loading }] =
    useCreateSubsidiaryEnterpriseMutation();
  const handleSave = (data: {
    name: string;
    sector?: CompanySector;
    size?: number;
    annualTurnover?: string;
    identificationNumber?: string;
    country?: string;
    structureType?: StringDropdownItem | null;
  }) => {
    createSubsidiary({
      variables: {
        input: {
          parent: {
            id: enterpriseParent.id,
          },
          name: data.name,
          structureType: data.structureType?.id as EnterpriseStructureType,
          country: data.country,
          metadata: {
            sector: data.sector,
            size: data.size,
            annualTurnover: data.annualTurnover,
            identificationNumber: data.identificationNumber,
          },
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:company.settings.updated.success'));
      })
      .catch((err: { message: string }) => {
        toast.openToastWithError(err.message);
      })
      .finally(() => {
        if (onSave) onSave();
      });
  };

  return (
    <div className={'flex flex-col gap-5'}>
      <EnterpriseInformationForm
        onSave={handleSave}
        ref={formRef}
        isLoading={loading}
        initialData={{
          name: '',
          country: enterpriseParent.country!,
          sector: enterpriseParent.metadata?.sector!,
        }}
      />
      <button className="primary" onClick={() => formRef.current?.submit()}>
        {t('global:save')}
      </button>
    </div>
  );
}
