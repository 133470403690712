import React from 'react';
import StringDropdown from '../../../../../../../generic/dropdown/StringDropdown';
import { useDimensionDropdownQuery } from '../../../../../../../../graphql/cms/generated';
import { useTranslation } from '@hooks/useTranslation';
import { SkeletonLoader } from '../../../../../../../generic/loader/SkeletonLoader';
import { cmsClient } from '../../../../../../../../graphql/clients/cmsClient';
import { Tag } from '../../../../../../../badges/Tag';
import {
  StringDropdownItem,
  StringDropdownProps,
} from '../../../../../../../generic/dropdown/StringDropdown.types';

export type DimensionDropdownItem = StringDropdownItem & {
  type: 'custom' | 'standard';
};
export type DimensionDropdownProps<
  M extends boolean = false
> = Omit<StringDropdownProps<DimensionDropdownItem, M>, 'availableItems'> & {
  multiple?: M;
};

export function DimensionDropdown<
  M extends boolean = false
>({
    multiple,
    ...props
  }: DimensionDropdownProps<M>) {
  const { i18n } = useTranslation();
  const { data, loading } = useDimensionDropdownQuery({
    client: cmsClient,
    variables: {
      locale: i18n.language,
    },
  });

  if (loading) {
    return <SkeletonLoader />;
  }

  const availableItems: DimensionDropdownItem[] =
    data?.dimensions.flatMap((dimension) => {
      if (dimension?.slug && dimension?.name) {
        return {
          id: dimension.slug,
          label: dimension.name,
          type: 'standard',
        };
      }
      return [];
    }) || [];

  return (
    <div>
      <StringDropdown<DimensionDropdownItem, M>
        availableItems={availableItems}
        itemFormatter={(item: DimensionDropdownItem) => (
          <Tag color={item.type === 'custom' ? 'green' : 'blue'}>
            {item.icon}
            <span className={'font-semibold'}>{item.label}</span>
          </Tag>
        )}
        multiple={multiple}
        {...props}
      />
    </div>
  );
}

