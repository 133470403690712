import { Field, Label } from '@headlessui/react';
import { Checkbox, CheckboxColors } from 'components/form/Checkbox';
import { DimensionItemDropdown_StrapiQuery } from 'graphql/cms/generated';
import {
  IndicatorValueByDimensionForm_DimensionFragment,
  StandardItemType,
  SurveyLanguage,
} from 'graphql/generated';
import {
  CustomDimensionItem,
  DimensionItem,
  EditIndicatorFormValues,
  isStandardDimensionItem,
  StandardDimensionItem,
} from '../editIndicator.types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from '@hooks/useTranslation';

const LocalizedName = ({
  item,
  language,
}: {
  item: NonNullable<
    DimensionItemDropdown_StrapiQuery['dimensionItems'][number]
  >;
  language?: SurveyLanguage;
}) => {
  const localization = item.localizations?.find(
    (loc) => loc?.locale === language,
  );
  return <>{localization ? localization.name : item.name}</>;
};

export const DimensionItemsList = ({
    dimension,
    inputName,
    availableItems,
    language
   }: {
  dimension: IndicatorValueByDimensionForm_DimensionFragment;
  availableItems: DimensionItemDropdown_StrapiQuery['dimensionItems'];
  inputName: 'dimension1' | 'dimension2';
  language?: SurveyLanguage;
}) => {
  const { setValue } = useFormContext<EditIndicatorFormValues>();
  const items = useWatch({
    name: `${inputName}.items`,
    defaultValue: [],
  });
  const { translateProperty } = useTranslation();

  return (
    <div className="w-full">
      <p className="font-semibold">{translateProperty(dimension, 'name', language)}</p>
      <div className="flex flex-wrap gap-2 my-7 max-w-3xl">
        {availableItems
          ?.filter((item): item is NonNullable<typeof item> => item !== null)
          .map((item) => {
            const isItemSelected = items.some(
              (selectedItem: StandardDimensionItem | CustomDimensionItem) =>
                isStandardDimensionItem(selectedItem)
                  ? item.slug === selectedItem.standardItemLink.slug
                  : false,
            );

            return (
              <Field className="flex items-center gap-2" key={item.slug}>
                <Checkbox
                  color={CheckboxColors.Purple}
                  checked={isItemSelected}
                  onChange={() => {
                    const updatedItems = isItemSelected
                      ? items.filter((selectedItem: DimensionItem) =>
                        isStandardDimensionItem(selectedItem)
                          ? selectedItem.standardItemLink.slug !== item.slug
                          : false,
                      )
                      : [
                        ...items,
                        {
                          i18n: [
                            {
                              language: SurveyLanguage.Fr,
                              name: item.name,
                            },
                          ],
                          standardItemLink: {
                            slug: item.slug,
                            type: StandardItemType.DimensionItem,
                          },
                        },
                      ];
                    setValue(`${inputName}.items`, updatedItems);
                  }}
                />
                <Label>
                  <LocalizedName item={item} language={language}/>
                </Label>
              </Field>
            );
          })}
      </div>
    </div>
  );
};
