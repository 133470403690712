import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { tailwindColors } from '../../../services/TailwindService';

export function DoughnutChart({
  data,
  labels,
  title,
}: {
  data: number[];
  labels: string[];
  title: string;
}) {
  // Enrich labels with value: replace 'label' with 'label (value)'
  const enrichedLabels = labels.map((label, index) => {
    return `${label} (${data[index]})`;
  });

  const doughnutChartData = {
    labels: enrichedLabels,
    datasets: [
      {
        data,
        backgroundColor: [
          tailwindColors.green['300'],
          tailwindColors.gray['100'],
        ],
      },
    ],
  };

  const doughnutChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        maxWidth: 600,
        labels: {
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-4">
      <div className="w-full">
        <Doughnut
          data={doughnutChartData}
          options={doughnutChartOptions}
          title={title}
        />
      </div>
    </div>
  );
}
