import React from 'react';
import { BlocksRenderer } from '../../../../cms/renderers/BlocksRenderer';
import { CmsComponentFootnotes_FootnoteFragment } from '../../../../../graphql/cms/generated';
import { ChevronDownIcon, InfoIcon } from '../../../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function Footnotes({
  footnotes,
}: {
  footnotes: CmsComponentFootnotes_FootnoteFragment[];
}) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);

  if (footnotes.length === 0) {
    return null;
  }

  return (
    <div className="space-y-2 bg-blue-100 px-4 py-2 rounded-xl">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-2 text-blue-900 font-bold text-sm">
          <InfoIcon />
          {t('disclosure_requirements.disclosure_requirement.footnotes')}
        </div>
        <button
          className="tertiary bg-transparent"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <ChevronDownIcon
            className={`w-4 h-4 ${isExpanded ? 'transform rotate-180' : ''}`}
          />
        </button>
      </div>
      {isExpanded &&
        footnotes.map((footnote, index) => (
          <BlocksRenderer
            key={`footnote_${index}`}
            content={footnote.content}
            blocks={{
              paragraph: ({ children }) => (
                <p className="text-blue-900 text-xs">{children}</p>
              ),
            }}
          />
        ))}
    </div>
  );
}
