import React from 'react';
import {
  JustificationType,
  useJustifyIroButtonQuery,
} from '../../../../../graphql/generated';
import { JustifyButton, JustifyButtonStyle } from '../JustifyButton';

export const JustifyIroButton = ({ iroId }: { iroId: string }) => {
  const type = JustificationType.Iro;

  const query = useJustifyIroButtonQuery({
    variables: {
      type,
      id: iroId,
    },
  });

  const latestJustificationId =
    query.data?.iroJustifications?.edges[0]?.node.id;

  return (
    <JustifyButton
      type={type}
      entityId={iroId}
      latestJustificationId={latestJustificationId}
      callback={query.refetch}
      style={JustifyButtonStyle.MessageIcon}
    />
  );
};
