import React, { Fragment, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { CheckIcon, ChevronDownIcon, XIcon } from '../../icons';
import { Float } from '@headlessui-float/react';
import { useTranslation } from '@hooks/useTranslation';
import {
  StringDropdownItem,
  StringDropdownProps,
} from './StringDropdown.types';

export default function StringDropdown<
  T extends StringDropdownItem,
  M extends boolean = false,
>({
  availableItems,
  item,
  setItem,
  extraPositionClassName,
  multiple = false as M,
  allowEmpty = false,
  filterable = false,
  itemFormatter = (item: T) => (
    <>
      {item?.icon} {item.label || '-'}
    </>
  ),
}: StringDropdownProps<T, M>) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');

  const chooseItem = (selectedItem: T | T[]): void => {
    if (multiple) {
      const selectedItems = Array.isArray(selectedItem)
        ? selectedItem
        : [selectedItem];

      // @ts-ignore
      setItem(selectedItems);
    } else {
      setItem(selectedItem as unknown as typeof item);
    }
  };

  const resetItem = () => {
    if (multiple) {
      setItem([] as unknown as typeof item);
    } else {
      setItem(null as typeof item);
    }
  };

  const filteredItems = filterable
    ? availableItems.filter((availableItem) =>
        availableItem.label.toLowerCase().includes(filter.toLowerCase()),
      )
    : availableItems;

  const displayLabel = multiple
    ? Array.isArray(item) && item.length > 0
      ? item.map((currentItem, index) => (
          <React.Fragment key={currentItem.id}>
            {index > 0 && !itemFormatter && ', '}
            {itemFormatter(currentItem)}
          </React.Fragment>
        ))
      : '-'
    : item
      ? itemFormatter(item as T)
      : '-';

  return (
    <Listbox
      value={item}
      onChange={(value) => {
        const flatValue =
          Array.isArray(value) && value.length === 1 && Array.isArray(value[0])
            ? value[0]
            : value;

        chooseItem(flatValue as T | T[]);
      }}
      multiple={multiple}
    >
      {({ open }) => (
        <Float portal adaptiveWidth>
          <div className="relative flex items-center">
            <ListboxButton className="form-input-select">
              <span className="flex items-center gap-1 w-full">
                {displayLabel}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            {allowEmpty && (multiple ? (item as T[]).length > 0 : item) && (
              <button className="unstyled">
                <XIcon
                  onClick={resetItem}
                  className="h-10 w-6 absolute right-8 flex items-center pr-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  aria-hidden="true"
                />
              </button>
            )}
          </div>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx(
                'absolute z-10 mt-1 w-full min-w-fit overflow-auto rounded-md bg-white text-base shadow-lg',
                'ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm max-h-60 py-1',
                extraPositionClassName,
              )}
            >
              <ListboxOptions static>
                {filteredItems.map((currentItem) => (
                  <ListboxOption
                    key={currentItem.id}
                    className={clsx(
                      'listbox-option group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                      'data-[selected]:active data-[focus]:text-white data-[focus]:bg-blue-600',
                    )}
                    value={currentItem}
                  >
                    {({ selected }) => (
                      <div className="flex items-center gap-1">
                        <div
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-1 shrink-0 flex items-center gap-1',
                          )}
                        >
                          {itemFormatter(currentItem)}
                        </div>

                        {selected && (
                          <div
                            className={clsx(
                              'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                            )}
                          >
                            <CheckIcon
                              className="h-5 w-5 shrink-0"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </Transition>
        </Float>
      )}
    </Listbox>
  );
}

export const enumToDropdownItems = (
  enumObject: object,
  labelFunction: (key: string) => string,
) => {
  return Object.keys(enumObject).map((key) => ({
    id: key,
    label: labelFunction(key),
  }));
};
