import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { ChevronDownIcon } from '../../icons';
import { Card } from '../card/Card';

interface AccordionItemProps {
  title: ReactNode;
  children: ReactNode;
  isExpanded?: boolean;
  onToggle?: () => void;
  className?: string;
  headerClassName?: string;
  headerProps?: { onClick?: () => void };
}

export const AccordionItem = ({
  title,
  children,
  isExpanded = false,
  onToggle,
  className,
  headerClassName,
  headerProps = {},
}: AccordionItemProps) => {
  const [internalExpanded, setInternalExpanded] = useState(isExpanded);

  const handleToggle = () => {
    if (onToggle) {
      onToggle();
    } else {
      setInternalExpanded(!internalExpanded);
    }
  };

  const isComponentExpanded = onToggle ? isExpanded : internalExpanded;

  return (
    <Card className={clsx(className)}>
      <div
        className={clsx(
          'flex items-center gap-2 cursor-pointer',
          headerClassName,
        )}
        onClick={handleToggle}
        {...headerProps}
      >
        <button className="unstyled" onClick={handleToggle}>
          <ChevronDownIcon
            className={`w-4 h-4 ${
              isComponentExpanded ? 'transform rotate-180' : ''
            }`}
          />
        </button>
        <div className="text-base font-bold grow">{title}</div>
      </div>
      {isComponentExpanded && (
        <div className="border-t border-gray-100 mt-4 p-4 space-y-2">
          {children}
        </div>
      )}
    </Card>
  );
};
