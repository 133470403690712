import React from 'react';
import { IndicatorList_Datapoint_StrapiFragment } from '../../../../../graphql/cms/generated';
import {
  IndicatorList_DatapointFragment,
  useIndicatorListQuery,
} from '../../../../../graphql/generated';
import { IndicatorItem } from './IndicatorItem';
import { useTranslation } from '@hooks/useTranslation';
import { DatapointTags } from '../../../datapoint/DatapointTags';
import { AddIndicatorButton } from './add/AddIndicatorButton';
import { NoData } from '../../../../generic/NoData';

export const IndicatorList = ({
  reportDatapoint,
  cmsDatapoint,
}: {
  reportDatapoint: IndicatorList_DatapointFragment;
  cmsDatapoint: IndicatorList_Datapoint_StrapiFragment;
}) => {
  const { t } = useTranslation();
  const { data } = useIndicatorListQuery({
    variables: {
      reportDataPointId: reportDatapoint.id,
    },
  });
  const indicators = data?.indicatorsByDataPoint || [];

  return (
    <div className="divide-y divide-gray-100 space-y-4">
      <div className="space-y-2">
        <div className="uppercase font-bold">{cmsDatapoint.identifier}</div>
        <h4>{cmsDatapoint.name}</h4>
        <DatapointTags datapoint={cmsDatapoint} />
      </div>

      {indicators.length > 0 ? (
        <div className="pt-4 space-y-4">
          <div className="flex items-center justify-end">
            <AddIndicatorButton
              reportDatapoint={reportDatapoint}
              cmsDatapoint={cmsDatapoint}
            />
          </div>
          <div className="space-y-4">
            {indicators.map((indicator) => (
              <IndicatorItem key={indicator.id} indicator={indicator} />
            ))}
          </div>
        </div>
      ) : (
        <NoData
          text={t(
            'indicator_collect.disclosure_requirement.indicator.create.description',
          )}
          actions={[
            {
              key: 'addIndicator',
              component: (
                <AddIndicatorButton
                  reportDatapoint={reportDatapoint}
                  cmsDatapoint={cmsDatapoint}
                />
              ),
            },
          ]}
        />
      )}
    </div>
  );
};
