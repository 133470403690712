import React, { useEffect, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useFormContext } from 'react-hook-form';
import { useModal } from '../../../../../layout/Modal';
import {
  ConfigureIndicatorDimensionInput,
  IndicatorListDocument,
  SurveyLanguage,
  useUpdateIndicatorMutation,
} from '../../../../../../graphql/generated';
import { ConfigureDimensionItems } from './configureDimensionItems/ConfigureDimensionItems';
import {
  DimensionItem,
  EditIndicatorButtonProps,
  EditIndicatorFormValues,
} from './editIndicator.types';
import { useToast } from '../../../../../layout/Toast';
import { useReportDataPoint } from '../../ReportDataPointProvider';
import LanguageDropdown from '../../../../../form/LanguageDropdown';
import { Loader } from 'components/generic/Loader';
import { XIcon } from 'components/icons';
import { IndicatorI18nNameInput } from './IndicatorI18nNameInput';

const isStandardDimensionItem = (
  item: any,
): item is { standardItemLink: string } => {
  return 'standardItemLink' in item;
};

export const EditIndicator = ({ indicator }: EditIndicatorButtonProps) => {
  const methods = useFormContext<EditIndicatorFormValues>();

  const { handleSubmit, watch, register, getValues } = methods;
  const { t } = useTranslation();
  const { reportDataPoint } = useReportDataPoint();
  const modal = useModal();
  const toast = useToast();

  const [updateIndicator, { loading: updatingIndicator }] =
    useUpdateIndicatorMutation();
  const dimensions = indicator?.dimensions || [];
  const dimension1 = dimensions[0];
  const dimension2 = dimensions[1];

  const [language, setLanguage] = useState<SurveyLanguage>(SurveyLanguage.Fr);
  const calculateCanSubmit = (values: EditIndicatorFormValues) => {
    return !!(
      !updatingIndicator &&
      values.i18n?.[0]?.name &&
      (!dimension1 || (values.dimension1?.items?.length ?? 0) > 0) &&
      (!dimension2 || (values.dimension2?.items?.length ?? 0) > 0)
    );
  };
  const [canSubmit, setCanSubmit] = useState(() =>
    calculateCanSubmit(getValues())
  );

  const pickLanguage = (newLanguage: SurveyLanguage) => {
    setLanguage(newLanguage);
  };

  const onSubmit = (data: EditIndicatorFormValues) => {
    const formattedDimensions = [];
    if (data.dimension1?.items && dimension1) {
      formattedDimensions.push({
        dimensionId: dimension1.id,
        items: data.dimension1.items.map((item) => {
          if (isStandardDimensionItem(item)) {
            return {
              i18n: item.i18n,
              standardItemLink: item.standardItemLink,
            };
          }
          return { i18n: item.i18n };
        }) as DimensionItem[],
      });
    }

    if (data.dimension2?.items && dimension2) {
      formattedDimensions.push({
        dimensionId: dimension2.id,
        items: data.dimension2.items.map((item) => {
          if (isStandardDimensionItem(item)) {
            return {
              i18n: item.i18n,
              standardItemLink: item.standardItemLink,
            };
          }
          return { i18n: item.i18n };
        }) as DimensionItem[],
      });
    }

    updateIndicator({
      variables: {
        input: {
          id: indicator.id,
          dimensions:
            formattedDimensions as unknown as ConfigureIndicatorDimensionInput[],
          i18n: data.i18n,
        },
      },
      refetchQueries: [
        {
          query: IndicatorListDocument,
          variables: {
            reportDataPointId: reportDataPoint,
          },
        },
      ],
      onCompleted: () => {
        modal.closeModal();
        toast.openToastWithMessage(
          t('toast:indicator_collect.disclosure_requirement.indicator.update.success'),
        );
      },
    });
  };
  useEffect(() => {
    const subscription = methods.watch((value) => {
      const canSubmitValue = !!(
        !updatingIndicator &&
        value.i18n?.[0]?.name &&
        (!dimension1 || (value.dimension1?.items?.length ?? 0) > 0) &&
        (!dimension2 || (value.dimension2?.items?.length ?? 0) > 0)
      );
      setCanSubmit(canSubmitValue);
    });
    return () => subscription.unsubscribe();
  }, [methods, updatingIndicator, dimension1, dimension2]);

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <div className="space-y-4">
            <div className="bg-gray-50 flex items-center gap-4 p-6 w-full justify-between border-b border-gray-100">
              <div className="w-12">
                <button
                  className="tertiary bg-transparent text-gray-100"
                  onClick={() => modal.closeModal()}
                >
                  <XIcon className="w-4 h-4" />
                </button>
              </div>
              <h4 className="grow">
                {t(
                  'indicator_collect.disclosure_requirement.indicator.edit.modal.title',
                )}
              </h4>
              <LanguageDropdown
                onUpdate={(language) => pickLanguage(language)}
              />
            </div>
            <div className="p-4 space-y-4">
              <div className="space-y-2">
                <label className="form-input-label">
                  {t(
                    'indicator_collect.disclosure_requirement.indicator.edit.modal.form.name',
                  )}
                </label>
                <IndicatorI18nNameInput language={language}/>
              </div>
              {dimension1 &&
              <ConfigureDimensionItems
                dimension1={dimension1}
                dimension2={dimension2}
                language={language}
              />}
              <button
                type="submit"
                className={'primary'}
                disabled={!canSubmit}
              >
                {updatingIndicator && <Loader />}
                <span>{t('global:save')}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
  );
};





