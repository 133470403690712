import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface DisclosureContextType {
  disclosureRequirementSlug: string;
  reportId: string;
  highlightedDatapointsSlugs: string[];
  setHighlightedDatapointsSlugs: Dispatch<SetStateAction<string[]>>;
  selectedDatapointSlug: string | null;
  selectDatapointSlug: (datapointSlug: string | null) => void;
}

interface DisclosureProviderProps {
  disclosureRequirementSlug: string;
  reportId: string;
  children: ReactNode;
}

const DisclosureContext = createContext<DisclosureContextType | undefined>(
  undefined,
);

export const DisclosureProvider: FC<DisclosureProviderProps> = ({
  disclosureRequirementSlug,
  reportId,
  children,
}) => {
  const [highlightedDatapointsSlugs, setHighlightedDatapointsSlugs] = useState<
    string[]
  >([]);
  const [selectedDatapointSlug, setSelectedDatapointSlug] = useState<
    string | null
  >(null);

  return (
    <DisclosureContext.Provider
      value={{
        reportId,
        disclosureRequirementSlug,
        highlightedDatapointsSlugs,
        setHighlightedDatapointsSlugs,
        selectedDatapointSlug,
        selectDatapointSlug: setSelectedDatapointSlug,
      }}
    >
      {children}
    </DisclosureContext.Provider>
  );
};

export const useDisclosure = (): DisclosureContextType => {
  const context = useContext(DisclosureContext);
  if (!context) {
    throw new Error('useDisclosure must be used within a DisclosureProvider');
  }
  return context;
};
