import {
  DatapointTag_DatapointFragment,
  DisclosureRequirementFragment,
} from '../../../../../graphql/cms/generated';
import { filterEmptyDocuments } from '../../../../cms/utils';

export const extractDatapointsFromDisclosureRequirement = (
  disclosureRequirement: DisclosureRequirementFragment,
): DatapointTag_DatapointFragment[] => {
  const level2Dr = disclosureRequirement.content?.dr_level_2 || [];
  const level3Dr = level2Dr.flatMap((drLevel2) => drLevel2?.dr_level_3 || []);
  const mdrs = disclosureRequirement.minimum_disclosure_requirements || [];
  const mdrLevel1 = mdrs.flatMap((mdr) => mdr?.content);
  const mdrLevel2 = mdrLevel1.flatMap((mdr1) => mdr1?.mdr_level_2 || []);
  const mdrLevel3 = mdrLevel2.flatMap((mdr2) => mdr2?.mdr_level_3 || []);
  const ar = disclosureRequirement.application_requirements || [];
  const arDatapoints = ar.flatMap((ar) => ar?.datapoints || []);
  const mdrLevel3Datapoints = mdrLevel3.flatMap(
    (mdr3) => mdr3?.datapoints || [],
  );
  const mdrLevel2Datapoints = mdrLevel2.flatMap(
    (mdr2) => mdr2?.datapoints || [],
  );
  const mdrLevel1Datapoints = mdrLevel1.flatMap(
    (mdr1) => mdr1?.datapoints || [],
  );
  const level3DrDatapoints = level3Dr.flatMap(
    (drLevel3) => drLevel3?.datapoints || [],
  );
  const level2DrDatapoints = level2Dr.flatMap(
    (drLevel2) => drLevel2?.datapoints || [],
  );

  const allDatapoints = [
    ...level2DrDatapoints,
    ...level3DrDatapoints,
    ...mdrLevel1Datapoints,
    ...mdrLevel2Datapoints,
    ...mdrLevel3Datapoints,
    ...arDatapoints,
  ].filter(filterEmptyDocuments);

  // Dedup by slug
  return Array.from(
    new Map(
      allDatapoints.map((datapoint) => [datapoint.slug, datapoint]),
    ).values(),
  );
};

export const extractGuidelinesFromDisclosureRequirement = (
  disclosureRequirement: DisclosureRequirementFragment,
) => {
  return (
    disclosureRequirement.guidances
      ?.filter(filterEmptyDocuments)
      .filter(filterEmptyDocuments) || []
  );
};
