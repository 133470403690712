import { useTranslation } from "@hooks/useTranslation";
import { NoData } from "../../../generic/NoData";
import { DoughnutChart } from "../../../generic/chart/DoughnutChart";
import React from "react";

export function SelectedDatapointsVsTotalDatapointsDoughnut({
                                                              selectedDatapointsCount,
                                                              totalDatapointsCount
                                                            }: {
  selectedDatapointsCount: number;
  totalDatapointsCount: number;
}) {
  const { t } = useTranslation();

  if (totalDatapointsCount === 0 && selectedDatapointsCount === 0) {
    return <NoData text={t("global:noResult")} actions={[]}></NoData>;
  }

  return (
    <DoughnutChart
      data={[
        selectedDatapointsCount,
        totalDatapointsCount - selectedDatapointsCount
      ]}
      labels={[
        t(
          "gap_analysis.synthesis.selectedDatapointsOverTotalChart.selectedDatapoints"
        ),
        t(
          "gap_analysis.synthesis.selectedDatapointsOverTotalChart.totalDatapoints"
        )
      ]}
      title={t("gap_analysis.synthesis.selectedDatapointsOverTotalChart.title")}
    />
  );
}