import React from 'react';
import { IndicatorList_Datapoint_StrapiFragment } from '../../../../../../graphql/cms/generated';
import { IndicatorList_DatapointFragment } from '../../../../../../graphql/generated';
import { useProjectContext } from '../../../../../../providers/ProjectContextProvider';
import { useModal } from '../../../../../layout/Modal';
import { PlusIcon } from '../../../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { AddEnterpriseIndicatorForDatapointModal } from './AddEnterpriseIndicatorForDatapointModal';
import { ReportDataPointProvider } from '../../ReportDataPointProvider';

export const AddIndicatorButton = ({
  reportDatapoint,
  cmsDatapoint,
}: {
  reportDatapoint: IndicatorList_DatapointFragment;
  cmsDatapoint: IndicatorList_Datapoint_StrapiFragment;
}) => {
  const { t } = useTranslation();
  const modal = useModal();
  const projectContext = useProjectContext();
  const enterpriseId = projectContext?.enterprise?.id || '';

  const openAddCompanyIndicatorModal = () => {
    modal.openModalWithComponent(
      <ReportDataPointProvider reportDataPoint={reportDatapoint.id}>
        <AddEnterpriseIndicatorForDatapointModal
          companyId={enterpriseId}
          reportDatapoint={reportDatapoint}
          cmsDatapoint={cmsDatapoint}
        />
      </ReportDataPointProvider>,
      t(
        'indicator_collect.disclosure_requirement.indicator.create.modal.title',
      ),
    );
  };

  return (
    <button className="secondary purple" onClick={openAddCompanyIndicatorModal}>
      <PlusIcon className="w-4 h-4" />
      <span>
        {t('indicator_collect.disclosure_requirement.indicator.create.cta')}
      </span>
    </button>
  );
};
