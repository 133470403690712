import React from 'react';
import { DisclosureRequirement_ArFragment } from '../../../../../graphql/cms/generated';
import { filterEmptyDocuments } from '../../../../cms/utils';
import { BlockWithDatapointsRenderer } from '../BlockWithDatapointsRenderer';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Footnotes } from './Footnotes';

export const ApplicationRequirementContent = ({
  applicationRequirement,
}: {
  applicationRequirement: DisclosureRequirement_ArFragment;
}) => {
  const footnotes =
    applicationRequirement.footnotes?.filter(filterEmptyDocuments) || [];
  const datapoints =
    applicationRequirement.datapoints.filter(filterEmptyDocuments);

  return (
    <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
      <div className="space-y-2">
        {datapoints.length > 0 ? (
          <BlockWithDatapointsRenderer
            content={applicationRequirement.content}
            datapoints={datapoints.map((d) => d.slug || '')}
          />
        ) : (
          <BlocksRenderer content={applicationRequirement.content} />
        )}
        {footnotes.length > 0 && <Footnotes footnotes={footnotes} />}
      </div>
    </div>
  );
};
