import {
  CompanySearchTemplateInput,
  CompanyTemplateSource,
  ProjectTemplates_CompanyFragment,
  useProjectTemplatesLazyQuery,
} from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { ArrowRightIcon, ChevronRightIcon, XIcon } from '../../../icons';
import { SourceFilter } from './SourceFilter';
import { LoaderFullscreen } from '../../../layout/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { CompanyLogo } from '../CompanyLogo';
import { isMaturityEvaluation, isSurvey } from '../../../../types/survey.types';

export const ProjectTemplates = ({
  setPreviewCompanyTemplateId,
}: {
  setPreviewCompanyTemplateId: (companyTemplateId: string | null) => void;
}) => {
  const modal = useModal();
  const currentUser = useCurrentUser();
  const { t } = useTranslation();

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<
    ProjectTemplates_CompanyFragment[]
  >([]);

  const selectSource = (source: CompanyTemplateSource) => {
    setSearchCriteria({ ...searchCriteria, source, page: 0 });
    refreshCompaniesList({ ...searchCriteria, source, page: 0 });
  };

  const incrementPage = () => {
    setSearchCriteria({
      ...searchCriteria,
      page: (searchCriteria.page || 0) + 1,
    });
    refreshCompaniesList({
      ...searchCriteria,
      page: (searchCriteria.page || 0) + 1,
    });
  };

  const decrementPage = () => {
    setSearchCriteria({
      ...searchCriteria,
      page: (searchCriteria.page || 0) - 1,
    });
    refreshCompaniesList({
      ...searchCriteria,
      page: (searchCriteria.page || 0) - 1,
    });
  };

  const [searchCriteria, setSearchCriteria] =
    useState<CompanySearchTemplateInput>({
      companyId: currentUser?.company?.id || '',
      source: CompanyTemplateSource.Goodsteps,
      page: 0,
    });

  const [searchCompanyTemplatesQuery] = useProjectTemplatesLazyQuery({
    fetchPolicy: 'network-only',
  });
  const refreshCompaniesList = useCallback(
    (searchCriteria: CompanySearchTemplateInput) => {
      setIsSearching(true);
      searchCompanyTemplatesQuery({
        variables: {
          input: searchCriteria,
        },
      })
        .then((result) => {
          result.data?.searchCompanyTemplates &&
            setSearchResult(result.data.searchCompanyTemplates);
        })
        .catch((err) => {
          console.error(err.message);
        })
        .finally(() => setIsSearching(false));
    },
    [searchCompanyTemplatesQuery],
  );

  // Load list on first render
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      refreshCompaniesList(searchCriteria);
    }
  }, [isFirstRender, refreshCompaniesList, searchCriteria]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50 border-b border-gray-100">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={() => modal.closeModal()}
        >
          <XIcon className="w-4 h-4" />
        </button>
        <h4 className="text-center grow">
          {t('project.create.chooseTemplateModal.title')}
        </h4>
      </div>

      <div className="flex overflow-hidden grow">
        <div className="bg-gray-50 w-3/12 p-8 divide-y divide-gray-100 space-y-8 overflow-y-scroll overflow-x-hidden">
          <div className="flex flex-col gap-1 pt-4">
            <h3 className="title-h5">
              {t('project.create.chooseTemplateModal.sourceFilter.title')}
            </h3>
            <SourceFilter
              source={CompanyTemplateSource.Goodsteps}
              selectedSourceFilter={searchCriteria.source}
              setSelectedSourceFilter={selectSource}
            />
            <SourceFilter
              source={CompanyTemplateSource.OwnTemplates}
              selectedSourceFilter={searchCriteria.source}
              setSelectedSourceFilter={selectSource}
            />
          </div>
        </div>

        <div className="w-9/12 grow p-8 overflow-hidden flex flex-col gap-8">
          <h1 className="max-w-3xl">
            {t('project.create.chooseTemplateModal.baseline')}
          </h1>
          <p className="text-gray-500">
            {t('project.create.chooseTemplateModal.description')}
          </p>

          {
            <div className="overflow-y-scroll">
              <div className="flex flex-col gap-4">
                {isSearching ? (
                  <LoaderFullscreen />
                ) : (
                  searchResult &&
                  currentUser?.company &&
                  searchResult.map((template) => (
                    <ProjectTemplateItem
                      template={template}
                      key={template.id}
                      setPreviewCompanyTemplateId={setPreviewCompanyTemplateId}
                    />
                  ))
                )}
              </div>

              {!isSearching && (
                <div className="flex items-center justify-between flex-row-reverse gap-2 w-full">
                  <button
                    className="tertiary small justify-self-end"
                    disabled={searchResult.length < 10}
                    onClick={incrementPage}
                  >
                    <div>
                      {t('project.create.chooseTemplateModal.continueSearch')}
                    </div>
                    <ArrowRightIcon className="w-4 h-4" />
                  </button>
                  {(searchCriteria.page || 0) > 0 && (
                    <button className="tertiary small" onClick={decrementPage}>
                      <ArrowRightIcon className="w-4 h-4 rotate-180" />
                      <div>
                        {t(
                          'project.create.chooseTemplateModal.previousResults',
                        )}
                      </div>
                    </button>
                  )}
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

const ProjectTemplateItem = ({
  template,
  setPreviewCompanyTemplateId,
}: {
  template: ProjectTemplates_CompanyFragment;
  setPreviewCompanyTemplateId: (companyTemplateId: string | null) => void;
}) => {
  const { t } = useTranslation();
  const pillars = template.referential?.pillars || [];
  const stakes = pillars.flatMap((pillar) => pillar.stakes);

  const stakeholderSurveys =
    template.surveys?.filter((survey) => isSurvey(survey)) || [];
  const maturityEvaluationSurveys =
    template.surveys?.filter((survey) => isMaturityEvaluation(survey)) || [];

  return (
    <div className="flex gap-8 items-center p-4 border border-gray-100 shadow-sm rounded-xl">
      <div className="w-8/12 flex items-center gap-8">
        <CompanyLogo companyId={template.id} size={'small'} />

        <div className="flex flex-col gap-2">
          <h2 className="title-h6 flex items-center gap-1">{template.name}</h2>
          <p className="text-gray-500 text-sm">
            {template.referential?.description}
          </p>
        </div>
      </div>
      <div className="w-2/12 text-gray-500 font-semibold flex flex-wrap gap-1">
        {pillars.length > 0 && (
          <div className="tag blue">{pillars.length} piliers</div>
        )}
        {stakes.length > 0 && (
          <div className="tag blue">{stakes.length} enjeux</div>
        )}
        {stakeholderSurveys.length > 0 && (
          <div className="tag blue">
            {stakeholderSurveys.length} consultation
            {stakeholderSurveys.length > 1 && 's'}
          </div>
        )}
        {maturityEvaluationSurveys.length > 0 && (
          <div className="tag blue">
            {maturityEvaluationSurveys.length} évaluation
            {maturityEvaluationSurveys.length > 1 && 's'} de maturité
          </div>
        )}
      </div>
      <div className="w-2/12 flex justify-end">
        <button
          className="primary purple small"
          onClick={() => setPreviewCompanyTemplateId(template.id)}
        >
          {t('translation:project.create.chooseTemplateModal.preview')}
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
};
