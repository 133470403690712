import {
  EditQuestionMenu_QuestionFragment,
  EditQuestionMenu_SurveyDocument,
  MoveDirection,
  useEditQuestionMenu_DeleteMutation,
  useEditQuestionMenu_MoveMutation,
} from '../../../../graphql/generated';
import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useToast } from '../../../layout/Toast';
import { DeleteIcon, DownIcon, UpIcon, VerticalDotsIcon } from '../../../icons';

export function EditQuestionMenu({
  question,
  isFirst,
  isLast,
}: {
  question: EditQuestionMenu_QuestionFragment;
  isFirst: boolean;
  isLast: boolean;
}) {
  const toast = useToast();
  const [deleteQuestionMutation] = useEditQuestionMenu_DeleteMutation();
  const [moveQuestionMutation] = useEditQuestionMenu_MoveMutation();

  const moveQuestion = (direction: MoveDirection) => {
    moveQuestionMutation({
      variables: {
        id: question.id,
        direction: direction,
      },
    }).catch((error) => {
      toast.openToastWithError(error.message);
    });
  };

  const deleteQuestion = () => {
    deleteQuestionMutation({
      variables: {
        id: question.id,
      },
      refetchQueries: [
        {
          query: EditQuestionMenu_SurveyDocument,
          variables: {
            id: question.survey.id,
          },
        },
      ],
    })
      .then((r) => {
        toast.openToastWithMessage('Question supprimée');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button className="unstyled text-gray-300">
            <VerticalDotsIcon className="w-3.5 h-3.5" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="z-50 absolute bg-white top-0 right-0 transform translate-y-6 w-56">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="flex flex-col items-start p-1 text-gray-500">
                  <button
                    className="contextual-menu-item"
                    disabled={isFirst}
                    onClick={() => {
                      moveQuestion(MoveDirection.Up);
                      close();
                    }}
                  >
                    <UpIcon className="w-4 h-4" />
                    <span>Monter</span>
                  </button>
                  <button
                    className="contextual-menu-item"
                    disabled={isLast}
                    onClick={() => {
                      moveQuestion(MoveDirection.Down);
                      close();
                    }}
                  >
                    <DownIcon className="w-4 h-4" />
                    <span>Descendre</span>
                  </button>
                  <button
                    className="contextual-menu-item"
                    onClick={() => {
                      deleteQuestion();
                      close();
                    }}
                  >
                    <DeleteIcon className="w-4 h-4" />
                    <span>Supprimer</span>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
