import React, { ReactNode } from 'react';
import { Loader } from '../../../../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { useToast } from '../../../../../layout/Toast';
import {
  IndicatorValuesMutationTypes,
  useIndicatorValueForm,
} from './IndicatorValueFormContext';

export const IndicatorValueFormContainer = <
  T extends IndicatorValuesMutationTypes,
>({
  children,
}: {
  children: ReactNode;
}) => {
  const { t } = useTranslation();
  const { errors, indicatorValues, mutation, loading } =
    useIndicatorValueForm<T>();
  const toast = useToast();

  const handleSubmit = async () => {
    if (errors.length !== 0) {
      toast.openToastWithError(errors.map((error) => error).join(', '));
      return;
    }

    // @ts-ignore
    await mutation(Object.values(indicatorValues)).then(() => {
      toast.openToastWithMessage(
        t('toast:indicator_collect.indicatorValue.create.success'),
      );
    });
  };

  return (
    <div className="flex flex-col items-stretch gap-4">
      {children}
      <div className="flex justify-between">
        <div />
        <button
          className="primary small"
          onClick={handleSubmit}
          disabled={loading || errors.length > 0}
        >
          {loading && <Loader />}
          {t('global:save')}
        </button>
      </div>
    </div>
  );
};
