import { useToast } from '../../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import {
  DefineDataPointInput,
  GapAnalysisDisclosureRequirement_DrBySlugDocument,
  useGapAnalysisDisclosureRequirement_DefineDataPointMutation,
  UseSaveDataPoint_DisclosureRequirementFragment,
} from '../../../../../graphql/generated';

export function useSaveDataPoint() {
  const toast = useToast();
  const { t } = useTranslation();
  const [defineDataPoint, { loading }] =
    useGapAnalysisDisclosureRequirement_DefineDataPointMutation();

  const saveDataPoint = (
    datapointSlug: string,
    disclosureRequirement: UseSaveDataPoint_DisclosureRequirementFragment,
    datapoint: Partial<DefineDataPointInput>,
  ) => {
    defineDataPoint({
      variables: {
        input: {
          disclosureRequirement: {
            id: disclosureRequirement.id,
          },
          referenceSlug: datapointSlug,
          ...datapoint,
        },
      },
      refetchQueries: [
        {
          query: GapAnalysisDisclosureRequirement_DrBySlugDocument,
          variables: {
            referenceSlug: disclosureRequirement.standardItemLink.slug,
            reportId: disclosureRequirement.reportTopic.report.id,
          },
        },
      ],
    })
      .then(() => {
        toast.openToastWithMessage(
          t(
            'toast:gap_analysis.disclosure_requirement.datapoint.define.success',
          ),
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return { saveDataPoint, loading };
}
