import React from 'react';
import {
  DeleteIndicatorButton_IndicatorFragment,
  IndicatorItem_IndicatorFragment,
  IndicatorListDocument,
  StandardEnum,
  useDeleteIndicatorButtonMutation,
  useIndicatorCollectDisclosureRequirement_DrBySlugLazyQuery,
} from '../../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { DeleteIcon } from '../../../../../icons';
import { useModal } from '../../../../../layout/Modal';
import { Loader } from '../../../../../generic/Loader';
import { useToast } from '../../../../../layout/Toast';
import { useDisclosure } from '../../../../gapAnalysis/disclosureRequirement/DisclosureContext';
import { useProjectContext } from '../../../../../../providers/ProjectContextProvider';
import {
  ReportDataPointProvider,
  useReportDataPoint,
} from '../../ReportDataPointProvider';

export const DeleteIndicatorButton = ({
  indicator,
}: {
  indicator: IndicatorItem_IndicatorFragment;
}) => {
  const modal = useModal();
  const { t } = useTranslation();

  const disclosureProvider = useDisclosure();
  const { reportDataPoint } = useReportDataPoint();
  const projectContext = useProjectContext();
  const csrdReport = projectContext?.enterprise?.reports?.find(
    (report) => report.standard === StandardEnum.Csrd,
  );

  const [disclosureRequirementQuery] =
    useIndicatorCollectDisclosureRequirement_DrBySlugLazyQuery({
      variables: {
        reportId: csrdReport?.id || '',
        referenceSlug: disclosureProvider.disclosureRequirementSlug,
      },
      fetchPolicy: 'cache-and-network',
    });

  const refreshDisclosureRequirement = () => {
    disclosureRequirementQuery();
  };

  const openDeleteModal = () => {
    if (disclosureProvider.selectedDatapointSlug) {
      modal.openModalWithComponent(
        <ReportDataPointProvider reportDataPoint={reportDataPoint}>
          <DeleteIndicatorForm
            indicator={indicator}
            callback={refreshDisclosureRequirement}
          />
        </ReportDataPointProvider>,
        t('indicator_collect.disclosure_requirement.indicator.delete.cta'),
        false,
        true,
      );
    }
  };

  return (
    <button className="contextual-menu-item" onClick={openDeleteModal}>
      <DeleteIcon />
      {t('indicator_collect.disclosure_requirement.indicator.delete.cta')}
    </button>
  );
};

const DeleteIndicatorForm = ({
  indicator,
  callback,
}: {
  indicator: DeleteIndicatorButton_IndicatorFragment;
  callback: () => void;
}) => {
  const modal = useModal();
  const toast = useToast();
  const { t } = useTranslation();
  const { reportDataPoint } = useReportDataPoint()

  const [deleteIndicatorMutation, { loading }] =
    useDeleteIndicatorButtonMutation();

  const deleteIndicator = (hardDelete: boolean) => {
    deleteIndicatorMutation({
      variables: {
        input: {
          id: indicator.id,
          hardDelete,
        },
      },
      refetchQueries: [
        {
          query: IndicatorListDocument,
          variables: {
            reportDataPointId: reportDataPoint
          }
        }
      ]
    })
      .then(() => {
        modal.closeModal();
        callback();
      })
      .catch((err) => {
        console.error(err.message);
        toast.openToastWithError(err.message);
      });
  };

  return (
    <div className="space-y-4">
      <div className="space-y-4">
        <div>
          {t(
            'indicator_collect.disclosure_requirement.indicator.delete.modal.form.description',
          )}
        </div>
        <div className="flex items-end justify-between">
          <button
            className="secondary gray"
            disabled={loading}
            onClick={modal.closeModal}
          >
            <span>{t('global:cancel')}</span>
          </button>
          <div className="flex flex-col items-end gap-2">
            <button
              className="primary danger"
              disabled={loading}
              onClick={() => deleteIndicator(true)}
            >
              {loading && <Loader />}
              <span>
                {t(
                  'indicator_collect.disclosure_requirement.indicator.delete.modal.form.cta_hardDelete',
                )}
              </span>
            </button>
            <button
              className="secondary danger"
              disabled={loading}
              onClick={() => deleteIndicator(false)}
            >
              {loading && <Loader />}
              <span>
                {t(
                  'indicator_collect.disclosure_requirement.indicator.delete.modal.form.cta_softDelete',
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
