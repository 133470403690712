import React, { ReactNode } from 'react';
import clsx from 'clsx';

interface CardProps {
  children: ReactNode;
  className?: string;
  header?: ReactNode;
  headerClassName?: string;
  onClick?: () => void;
  isSelected?: boolean;
}

export const Card = ({
  children,
  className,
  header,
  headerClassName,
  onClick,
  isSelected,
}: CardProps) => {
  const isClickable = !!onClick;
  return (
    <div
      className={clsx(
        'border border-gray-100 bg-white rounded-xl w-full',
        isClickable && 'cursor-pointer hover:border-gray-900',
        isSelected && 'border-gray-900',
        className,
      )}
      onClick={onClick}
    >
      {header && (
        <div className={clsx('flex items-center gap-2 p-4', headerClassName)}>
          {header}
        </div>
      )}
      <div className="p-4">{children}</div>
    </div>
  );
};
