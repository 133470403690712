import React from 'react';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { ReferentialProvider } from '../../../../components/project/context/ReferentialContext';
import { TabNavigationLayout } from '../../../../components/generic/nav/TabNavigationLayout';
import { IRO_TABS, useIroTabs } from '../useIroTabs';
import { IroSettings } from '../../../../components/project/iro/settings/IroSettings';
import { useIroSettingsScreenQuery } from '../../../../graphql/generated';
import { IroHeader } from '../IroHeader';

function IroSettingsInner() {
  const projectContext = useProjectContext();
  const { t } = useTranslation();
  const companyReferentialDocumentQuery = useIroSettingsScreenQuery({
    variables: {
      referentialId: projectContext?.enterprise?.referential?.id || '',
    },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'network-only',
  });

  const iroTabs = useIroTabs();

  if (companyReferentialDocumentQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (companyReferentialDocumentQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {companyReferentialDocumentQuery.error.message}
      </MessageBox>
    );
  }

  if (companyReferentialDocumentQuery.data?.referential) {
    return (
      <ReferentialProvider
        initialReferential={
          companyReferentialDocumentQuery.data?.referential.id
        }
      >
        <PageTitle title={t('screen.iro.iro_settings')} />
        <Breadcrumb />

        <TabNavigationLayout<IRO_TABS>
          tabs={iroTabs}
          activeTab={IRO_TABS.SETTINGS}
          renderTabs={(tabsContent) => (
            <div>
              <IroHeader />
              {tabsContent}
            </div>
          )}
        >
          <div className="w-full p-8">
            <IroSettings
              referential={companyReferentialDocumentQuery.data?.referential}
            />
          </div>
        </TabNavigationLayout>
      </ReferentialProvider>
    );
  }

  return null;
}

export const IroSettingsScreen = withProjectContext(IroSettingsInner, {
  requiredCompany: true,
});
