import React from 'react';
import { IroScaleItem } from '../picker/IroScalePicker';
import { IroScopeItem } from '../picker/IroScopePicker';
import { IroLikelihoodItem } from '../picker/IroLikelihoodPicker';
import { IroIrremediabilityItem } from '../picker/IroIrremediabilityPicker';
import { useTranslation } from '@hooks/useTranslation';
import { GradientTag } from '../../../../generic/GradientTag';
import {
  IrremediabilityValueDefinition,
  LikelihoodValueDefinition,
  ScaleValueDefinition,
  ScopeValueDefinition,
} from '../../../../../graphql/generated';
import { InnerHtml } from '../../../../generic/InnerHtml';

export function CriteriaRatingTable({
  items,
  definitions,
}: {
  items:
    | IroScaleItem[]
    | IroScopeItem[]
    | IroLikelihoodItem[]
    | IroIrremediabilityItem[];
  definitions:
    | ScaleValueDefinition[]
    | ScopeValueDefinition[]
    | LikelihoodValueDefinition[]
    | IrremediabilityValueDefinition[];
}) {
  const { t } = useTranslation();
  return (
    <table>
      <thead>
        <tr>
          <th>{t('iro.criteria.rating.header')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const definition = definitions.find((d) => d.value === item.id);
          return (
            <tr key={item.id}>
              <td>
                <GradientTag name={item.name} strength={item.strength} />
                {definition?.definition && (
                  <InnerHtml html={definition.definition} />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
