import React from 'react';
import {
  IndicatorItem_IndicatorFragment,
  IndicatorValueForm_LatestIndicatorValueFragment,
  useIndicatorValueForm_LatestIndicatorValuesQuery,
} from '../../../../../../../graphql/generated';
import { TableSkeletonLoader } from '../../../../../../generic/loader/TableSkeletonLoader';
import {
  isIntegerType,
  isNarrativeType,
} from '../../../../../../../types/indicatorValue.types';
import {
  NarrativeIndicatorValueForm,
  NarrativeIndicatorValueType,
} from './type/narrative/NarrativeIndicatorValueForm';
import {
  IntegerIndicatorValueForm,
  IntegerIndicatorValueType,
} from './type/integer/IntegerIndicatorValueForm';

type Props = {
  indicator: IndicatorItem_IndicatorFragment;
  enterpriseId?: string;
};

export const IndicatorValueForm = ({
  indicator,
  enterpriseId,
}: Props) => {
  const { data, loading } =
    useIndicatorValueForm_LatestIndicatorValuesQuery({
      variables: {
        indicatorId: indicator.id,
        enterpriseId: enterpriseId ?? undefined,
      },
    });

  if (loading) {
    return <TableSkeletonLoader columns={1} rows={1} />;
  }
  const indicatorValue = data?.latestIndicatorValues[0] ?? {
    __typename: indicator.type,
    enterprise: enterpriseId ? { id: enterpriseId } : undefined,
    indicator: { id: indicator.id },
  } as IndicatorValueForm_LatestIndicatorValueFragment

  return <TypeIndicatorValueForm {...{indicatorValue, indicator }} />;
};

export const TypeIndicatorValueForm = ({indicatorValue, indicator}: {indicatorValue: IndicatorValueForm_LatestIndicatorValueFragment, indicator: IndicatorItem_IndicatorFragment}) => {
  return (isNarrativeType<NarrativeIndicatorValueType>(indicatorValue) && (
      <NarrativeIndicatorValueForm indicatorValue={{ narrative_value: indicatorValue.narrative_value, ...prepareInput(indicatorValue, indicator) }} />
    )) ||
    (isIntegerType<IntegerIndicatorValueType>(indicatorValue) && (
      <IntegerIndicatorValueForm indicatorValue={{ integer_value: indicatorValue.integer_value, ...prepareInput(indicatorValue, indicator) }} />
    )) || <div>Unknown indicatorValue type</div>
}

export const prepareInput = (indicatorValue: IndicatorValueForm_LatestIndicatorValueFragment, indicator: IndicatorItem_IndicatorFragment)=> {
  return {
    indicator: { id: indicator.id },
    dimensionItems: indicatorValue.dimensionItems?.flatMap((item) => {
      if (!item.id) {
        return []
      }

      return {
        id: item.id
      }}) || [],
    enterprise: indicatorValue.enterprise ? { id: indicatorValue.enterprise.id } : undefined,
  }
}
