import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { IndicatorItem_IndicatorFragment } from '../../../../../../graphql/generated';
import { useIndicatorValueTypeMutation } from './form/type/useIndicatorValueTypeMutation';
import { IntegerIndicatorValueFormMutationTypes } from './form/type/integer/IntegerIndicatorValueForm';
import { NarrativeIndicatorValueFormMutationTypes } from './form/type/narrative/NarrativeIndicatorValueForm';
import { generateHash } from './generateHash';

export type IndicatorValuesMutationTypes =
  | NarrativeIndicatorValueFormMutationTypes
  | IntegerIndicatorValueFormMutationTypes;

interface IndicatorValueFormContextType<T extends IndicatorValuesMutationTypes> {
  indicator: IndicatorItem_IndicatorFragment;
  indicatorValues: T['input'];
  addIndicatorValue: (item: T['item']) => void;
  errors: string[];
  setErrors: Dispatch<SetStateAction<string[]>>;
  organizationalUnit?: string;
  setOrganizationalUnit: Dispatch<SetStateAction<string | undefined>>;
  mutation: T['mutation']
  loading: boolean
}

interface IndicatorValueFormProviderProps<T extends IndicatorValuesMutationTypes> {
  indicator: IndicatorItem_IndicatorFragment;
  children: ReactNode;
  orgUnit?: string
}

function createIndicatorValueFormContext<T extends IndicatorValuesMutationTypes>() {
  return createContext<IndicatorValueFormContextType<T> | undefined>(undefined);
}

export const IndicatorValueFormContext = createIndicatorValueFormContext<any>();
export const IndicatorValueFormProvider = <T extends IndicatorValuesMutationTypes>({
    indicator,
    children,
    orgUnit,
  }: IndicatorValueFormProviderProps<T>) => {
  const [indicatorValues, setIndicatorValues] = useState<Record<string, T['item']>>({});
  const [errors, setErrors] = useState<string[]>([]);
  const [organizationalUnit, setOrganizationalUnit] = useState<string | undefined>(orgUnit);

  const addIndicatorValue = async (item: T['item']) => {
    const { indicator, dimensionItems, enterprise } = item;
    const hash = await generateHash(
      indicator.id,
      dimensionItems?.map((item) => item.id) ?? [],
      enterprise?.id,
    );
    const original = indicatorValues[hash];
    setIndicatorValues({
      ...indicatorValues,
      [hash]: original ? { ...original, ...item } : item,
    });
  };

  const { mutation, loading } = useIndicatorValueTypeMutation(indicator, organizationalUnit);

  return (
    <IndicatorValueFormContext.Provider
      value={{
        indicator,
        indicatorValues,
        addIndicatorValue,
        errors,
        setErrors,
        organizationalUnit,
        setOrganizationalUnit,
        mutation,
        loading,
      }}
    >
      {children}
    </IndicatorValueFormContext.Provider>
  );
};


export const useIndicatorValueForm = <T extends IndicatorValuesMutationTypes>(): IndicatorValueFormContextType<T> => {
  const context = useContext(IndicatorValueFormContext);
  if (!context) {
    throw new Error('useIndicatorValueForm must be used within a IndicatorValueFormProvider');
  }
  return context as IndicatorValueFormContextType<T>;
};
