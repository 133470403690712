import React, { useMemo, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';
import { Table } from '../../../../molecules/table/Table';
import { XIcon } from '../../../../icons';
import { useModal } from '../../../../layout/Modal';
import { useToast } from '../../../../layout/Toast';
import {
  GapAnalysisSummary_ReportFragment,
  GapAnalysisSummary_StakeFragment,
  GapAnalysisSummaryDocument,
  StandardEnum,
  useChooseReportDisclosureRequirementModalMutation,
} from '../../../../../graphql/generated';
import {
  ChooseReportDisclosureRequirementsRow,
  prepareData,
  SelectedData,
  useChooseReportDisclosureRequirementsColumns,
} from './useChooseReportDisclosureRequirementsColumns';
import { SummaryDetailDisclosureRequirementsRow } from './SummaryDetailDisclosureRequirementsRow';
import { getSortedRowModel } from '@tanstack/react-table';
import { useProjectContext } from '../../../../../providers/ProjectContextProvider';
import {
  DisclosureRequirement,
  GapAnalysisSummary_Topic_TreeFragment,
  Topic
} from "../../../../../graphql/cms/generated";

export const ChooseReportDisclosureRequirementsModal = ({
  report,
  cmsTopics,
  stakes,
}: {
  report: GapAnalysisSummary_ReportFragment;
  cmsTopics: GapAnalysisSummary_Topic_TreeFragment[];
  stakes: GapAnalysisSummary_StakeFragment[];
}) => {
  const { t } = useTranslation();
  const modal = useModal();
  const toast = useToast();
  const context = useProjectContext();
  const [chooseDisclosureRequirementsToReport] =
    useChooseReportDisclosureRequirementModalMutation();

  const [selectedData, setSelectedData] = useState<SelectedData>(() => {
    const initialState: SelectedData = {};
    report.topics.forEach((topic) => {
      initialState[topic.standardItemLink.slug] = {
        topicSelected: !topic.disabled,
        disclosureRequirements: topic.disclosureRequirements
          .filter((dr) => !dr.disabled)
          .map((dr) => dr.standardItemLink.slug),
      };
    });
    return initialState;
  });

  const toggleTopicSelection = (referenceSlug: string) => {
    setSelectedData((prevState) => {
      const currentTopicData = prevState[referenceSlug] || {
        topicSelected: false,
        disclosureRequirements: [],
      };
      const topicInData = cmsTopics.find(
        (topic) => topic?.slug === referenceSlug,
      );
      const disclosureRequirements =
        topicInData?.disclosure_requirements
          ?.map((dr) => dr?.slug)
          .filter(
            (slug): slug is string => slug !== null && slug !== undefined,
          ) || [];

      return {
        ...prevState,
        [referenceSlug]: {
          ...currentTopicData,
          topicSelected: !currentTopicData.topicSelected,
          disclosureRequirements: !currentTopicData.topicSelected
            ? disclosureRequirements
            : [],
        },
      };
    });
  };

  const toggleDisclosureRequirementSelection = (
    topicReferenceSlug: string,
    disclosureRequirementSlug: string,
  ) => {
    setSelectedData((prevState) => {
      const currentTopicData = prevState[topicReferenceSlug] || {
        topicSelected: false,
        disclosureRequirements: [],
      };

      const updatedDisclosureRequirements =
        currentTopicData.disclosureRequirements.includes(
          disclosureRequirementSlug,
        )
          ? currentTopicData.disclosureRequirements.filter(
              (slug) => slug !== disclosureRequirementSlug,
            )
          : [
              ...currentTopicData.disclosureRequirements,
              disclosureRequirementSlug,
            ];

      return {
        ...prevState,
        [topicReferenceSlug]: {
          ...currentTopicData,
          disclosureRequirements: updatedDisclosureRequirements,
        },
      };
    });
  };

  const columns = useChooseReportDisclosureRequirementsColumns(
    toggleTopicSelection,
    selectedData,
  );

  let tableData: ChooseReportDisclosureRequirementsRow[] = [];
  tableData = prepareData(cmsTopics, stakes);

  const listMaterialStakes = ():
    { disclosureRequirementsSlugs: string[], topic: GapAnalysisSummary_Topic_TreeFragment }[] => {
    const materialStakes: {topic:GapAnalysisSummary_Topic_TreeFragment, disclosureRequirementsSlugs: string[]}[] = [];
    cmsTopics.forEach((topic) => {
      const stakesForTopic =
        tableData.find((row) => row.topic?.slug === topic.slug)?.stakes || [];

      const hasMaterialStake = stakesForTopic.some(
        (stake) => !stake.isDisabled,
      );

      if (hasMaterialStake) {
        const disclosureRequirementsSlugs =
          topic.disclosure_requirements
            ?.map((dr) => dr?.slug)
            .filter(
              (slug): slug is string => slug !== null && slug !== undefined,
            ) || [];
        materialStakes.push({topic, disclosureRequirementsSlugs});
      }
    });
    return materialStakes;
  }

  const selectMaterialTopics = () => {
    setSelectedData((prevState) => {
      const newSelectedData = { ...prevState };

      listMaterialStakes().forEach(({topic, disclosureRequirementsSlugs}) => {
        newSelectedData[topic.slug] = {
                topicSelected: true,
                disclosureRequirements: disclosureRequirementsSlugs,
               };
      });
      toast.openToastWithMessage(t('toast:gap_analysis.chooseReportTopicModal.onSelectMaterialTopics.success',
        {count: listMaterialStakes().length,
          context:
            listMaterialStakes().length > 1 ? 'plural' : 'singular',}));
      return newSelectedData;
    });
  };

  const totalSelectedDisclosureRequirements = useMemo(() => {
    return Object.values(selectedData).reduce(
      (total, topic) => total + topic.disclosureRequirements.length,
      0,
    );
  }, [selectedData]);

  const handleSubmit = async () => {
    const topics = cmsTopics
      .map((cmsTopic) => {
        const selectedTopic = selectedData[cmsTopic.slug];
        const selectedDisclosureRequirements =
          selectedTopic?.disclosureRequirements || [];

        const allDisclosureRequirements = cmsTopic.disclosure_requirements.map(
          (dr) => {
            const isSelected = selectedDisclosureRequirements.includes(
              dr?.slug!,
            );
            return {
              referenceSlug: dr?.slug || '',
              disabled: !isSelected,
            };
          },
        );

        const hasSelectedDisclosureRequirements =
          allDisclosureRequirements.some((dr) => !dr.disabled);

        if (!hasSelectedDisclosureRequirements) {
          return null;
        }

        return {
          referenceSlug: cmsTopic.slug,
          disclosureRequirements: allDisclosureRequirements,
        };
      })
      .filter((topic): topic is NonNullable<typeof topic> => topic !== null);

    try {
      await chooseDisclosureRequirementsToReport({
        variables: {
          input: {
            id: report.id,
            topics,
          },
        },
        refetchQueries: [
          {
            query: GapAnalysisSummaryDocument,
            variables: {
              input: {
                company: { id: context?.enterprise?.id! },
                standard: StandardEnum.Csrd,
              },
            },
          },
        ],
      });

      toast.openToastWithMessage(
        t('toast:gap_analysis.chooseReportTopicModal.success', {
          count: totalSelectedDisclosureRequirements,
        }),
      );
      modal.closeModal();
    } catch (error) {
      toast.openToastWithError(
        t('toast:gap_analysis.chooseReportTopicModal.error'),
      );
    }
  };

  return (
    <div className="flex flex-col max-w-5xl">
      <div className="bg-gray-50 flex items-center gap-4 p-6 w-full justify-between border-b border-gray-100">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-100"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <h4 className="grow">{modal.title}</h4>
        <button className="primary purple" onClick={handleSubmit}>
          {t('gap_analysis.chooseReportTopicModal.submit.label', {
            count: totalSelectedDisclosureRequirements,
            context:
              totalSelectedDisclosureRequirements > 1 ? 'plural' : 'singular',
          })}
        </button>
      </div>

      <div className="flex flex-col gap-4 px-12 pt-8 overflow-scroll h-[calc(100vh-200px)]">
        <h1 className="text-2xl font-light">
          {t('gap_analysis.chooseReportTopicModal.title', {
            companyName: context?.enterprise?.name,
          })}
        </h1>

        <MessageBox type={MessageBoxType.Info}>
          <div className="flex gap-4 justify-between align-center items-center">
            {t('gap_analysis.chooseReportTopicModal.info')}
            <button className="small secondary" disabled={listMaterialStakes().length==0} onClick={selectMaterialTopics}>
              {t('gap_analysis.chooseReportTopicModal.selectMaterialTopics', {count: listMaterialStakes().length})}
            </button>
          </div>
        </MessageBox>

        <div className="space-y-4">
          {(tableData.length === 0 && (
            <MessageBox type={MessageBoxType.Info}>
              {t('global:noResult')}
            </MessageBox>
          )) || (
            <Table<ChooseReportDisclosureRequirementsRow>
              {...{
                tableClassName: 'table table-fixed',
                columns,
                data: tableData,
                enableSorting: true,
                enableExpanding: true,
                initialState: {
                  sorting: [
                    {
                      id: 'topic',
                      desc: false,
                    },
                  ],
                },
                getSortedRowModel: getSortedRowModel(),
                expandedRowRenderFn: (data, columnLength) => (
                  <SummaryDetailDisclosureRequirementsRow
                    data={data}
                    columnLength={columnLength}
                    selectedData={selectedData}
                    toggleDisclosureRequirementSelection={
                      toggleDisclosureRequirementSelection
                    }
                  />
                ),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
