import {
  MaterialityTypes,
  StakesMaterialityChart_StakeFragment,
  StakesQualityChart_AnswerFragment,
} from '../../../../../../graphql/generated';
import React, { useState } from 'react';
import StringDropdown from '../../../../../generic/dropdown/StringDropdown';
import { StringDropdownItem } from '../../../../../generic/dropdown/StringDropdown.types';
import { TStakeMateriality } from '../../../../survey/questionsDataviz/stakesMaterialityChart/StakesMaterialityChart';
import PillarDropdown from '../../../../../form/PillarDropdown';
import { StakeTag } from '../../../../../stake/StakeTag';
import { QualityBarChart } from '../../../../survey/questionsDataviz/QualityBarChart';
import { ExportMatrixContentToXlsButton } from './ExportMatrixContentToXlsButton';
import { mapQualityLevelToNumber } from './utils';
import { StakeQualityAverageType } from './types';

export const StakesQualityChart = ({
  stakes,
  answers,
  materialityType,
}: {
  stakes: StakesMaterialityChart_StakeFragment[];
  answers: StakesQualityChart_AnswerFragment[];
  materialityType: MaterialityTypes;
}) => {
  enum SortBy {
    QualityAsc = 'QualityAsc',
    QualityDesc = 'QualityDesc',
    MaterialityScore = 'MaterialityScore',
  }

  const [sortBy, setSortBy] = useState<SortBy>(SortBy.QualityDesc);
  const sortByItems: StringDropdownItem[] = [
    { id: SortBy.QualityDesc, label: 'Qualité (desc)' },
    { id: SortBy.QualityAsc, label: 'Qualité (asc)' },
    { id: SortBy.MaterialityScore, label: 'Score de matérialité' },
  ];

  const [filterByPillarId, setFilterByPillarId] = useState<string | null>(null);

  const stakesMaterialities: TStakeMateriality[] = stakes.map((stake) => {
    const impact = stake.impactMaterialityScore || 0;
    const financial = stake.financialMaterialityScore || 0;
    return {
      stake,
      xMateriality: financial,
      yMateriality: impact,
      materialityScore: Math.max(impact, financial),
    };
  });

  // Build a map stake -> quality sum and count from answers
  type StakeQualityMapType = {
    [stakeId: string]: { sum: number; count: number };
  };

  const stakeQualityMap: StakeQualityMapType = (answers || [])
    .flatMap((answer) => answer.stakes)
    .reduce((acc: StakeQualityMapType, stakeAnswer) => {
      const stakeId: string = stakeAnswer?.stakeId || '';
      const level = mapQualityLevelToNumber(stakeAnswer?.quality);
      if (acc[stakeId]) {
        acc[stakeId].sum += level;
        acc[stakeId].count++;
      } else {
        acc[stakeId] = { sum: level, count: 1 };
      }

      return acc;
    }, {});

  // Build an array of {stakeId, quality average} sorted by quality average
  const stakeQualityAverageMap: StakeQualityMapType = Object.keys(
    stakeQualityMap,
  ).reduce((acc: StakeQualityMapType, stakeId) => {
    acc[stakeId] = {
      sum: stakeQualityMap[stakeId].sum / stakeQualityMap[stakeId].count,
      count: 1,
    };
    return acc;
  }, {});

  const stakeQualityAverageArray: StakeQualityAverageType[] = Object.keys(
    stakeQualityAverageMap,
  )
    .map((stakeId) => {
      const stake = stakes.find((stake) => stake.id === stakeId);
      return {
        stake: stake,
        qualityAverage: stakeQualityAverageMap[stakeId].sum,
      };
    })
    .filter((stakeQualityAverage) => {
      if (stakeQualityAverage.stake && filterByPillarId) {
        return (
          stakeQualityAverage.stake.pillar?.id === filterByPillarId || false
        );
      }
      return true;
    })
    .toSorted((a, b) => {
      switch (sortBy) {
        case SortBy.QualityDesc:
          return b.qualityAverage - a.qualityAverage;
        case SortBy.QualityAsc:
          return a.qualityAverage - b.qualityAverage;
        case SortBy.MaterialityScore: {
          // Sort by materiality score from stakesMaterialities
          const aMaterialityScore = stakesMaterialities.find(
            (s) => s.stake.id === a.stake?.id,
          )?.materialityScore;
          const bMaterialityScore = stakesMaterialities.find(
            (s) => s.stake.id === b.stake?.id,
          )?.materialityScore;
          return (bMaterialityScore || 0) - (aMaterialityScore || 0);
        }
        default:
          return 0;
      }
    });

  // Display stake -> qualityBarChart
  return (
    <div className="space-y-1 w-full">
      <div className="flex items-center gap-4 justify-end pb-2">
        <div className="flex items-center gap-2 w-1/4">
          <label className="form-input-label">Trier par</label>
          <StringDropdown
            availableItems={sortByItems}
            item={
              sortByItems.find((item) => item.id === sortBy) || sortByItems[0]
            }
            setItem={(item: StringDropdownItem | null) =>
              setSortBy(item?.id as SortBy)
            }
          />
        </div>
        <div className="flex items-center gap-2">
          <label className="form-input-label">Filtrer par</label>
          <PillarDropdown
            currentPillarId={filterByPillarId}
            setPillarId={setFilterByPillarId}
            isFilter={true}
          />
        </div>
        <ExportMatrixContentToXlsButton
          stakeQualityArray={stakeQualityAverageArray}
          materialityType={materialityType}
        />
      </div>
      {stakeQualityAverageArray.map((stakeQualityAverage) => {
        //find stake
        return (
          <div
            className="flex flex-row items-center gap-2 w-full"
            key={stakeQualityAverage?.stake?.id}
          >
            <div className="flex items-center gap-2 w-1/3">
              <StakeTag stake={stakeQualityAverage.stake} />
            </div>
            <div className="w-2/3">
              <QualityBarChart value={stakeQualityAverage.qualityAverage} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
